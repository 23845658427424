const locale_en_th: { [key: string]: string[] } = {
  Add: ["Add", "เพิ่ม"],
  Added: ["Added", "เพิ่มแล้ว"],
  AddName: ["Add {{name}}", "เพิ่ม{{name}}"],
  "Add Answer": ["Add Answer", "เพิ่มคำตอบ"],
  "Add Choice": ["Add Choice", "เพิ่มตัวเลือก"],
  "Add Highlight": ["Add Highlight", "เพิ่มไฮไลท์"],
  "Add Matching": ["Add Matching", "เพิ่มการจับคู่"],
  "Add Panel": ["Add Panel", "เพิ่มกรอบ"],
  "Add Question": ["Add Question", "เพิ่มข้อสอบ"],
  "Add Slide": ["Add Slide", "เพิ่มสไลด์"],
  Album: ["Album", "อัลบั้ม"],
  Albums: ["Albums", "อัลบั้ม"],
  Amount: ["Amount", "จำนวน"],
  Answer: ["Answer", "คำตอบ"],
  "Answer Text": ["Answer Text", "ข้อความคำตอบ"],
  Back: ["Back", "กลับ"],
  Book: ["Book", "หนังสือ"],
  Browse: ["Browse", "เลือก"],
  Cancel: ["Cancel", "ยกเลิก"],
  Categories: ["Categories","หมวดหมู่"],
  Card: ["Card", "การ์ด"],
  Change: ["Change", "เปลี่ยน"],
  ChangeLanguage: ["Change Language", "เปลี่ยนภาษา"],
  "Change title": ["Change title", "แก้ไขชื่อ"],
  "Change Image": ["Change Image", "เปลี่ยนรูปภาพ"],
  Choice: ["Choice", "ตัวเลือก"],
  Choose: ["Choose", "เลือก"],
  "Choose Image": ["Choose Image", "เลือกรูปภาพ"],
  Class: ["Class", "คาบเรียน"],
  Clear: ["Clear", "ล้าง"],
  Close: ["Close", "ปิด"],
  ClosePath: ["Close Path", "ปิดเส้นทาง"],
  Collection: ["Collection", "คอลเล็คชัน"],
  Component: ["Component", "ส่วนประกอบ"],
  Confirm: ["Confirm", "ยืนยัน"],
  "Confirm Password": ["Confirm Password", "ยืนยันรหัสผ่าน"],
  Course: ["Course", "หลักสูตร"],
  Cover: ["Cover", "ภาพปก"],
  Dark: ["Dark", "มืด"],
  DarkMode: ["Dark Mode", "ธีมสีเข้ม"],
  Date: ["Date", "วันที่"],
  Detail: ["Detail", "รายละเอียด"],
  Description: ["Description", "รายละเอียด"],
  Deselect: ["Deselect", "ยกเลิกการเลือก"],
  DisplayName: ["Display Name", "ชื่อที่ใช้แสดง"],
  "Display Name": ["Display Name", "ชื่อที่แสดง"],
  Divider: ["Divider", "เส้นคั่น"],
  "Do you want to remove?": ["Do you want to remove ?", "คุณต้องการลบหรือไม่?"],
  DoYouWantToRemove: [
    'Do you want to remove "{{name}}" ?',
    'คุณต้องการลบ "{{name}}" หรือไม่?',
  ],
  DoYouWantToRemoveNameForever: [
    `Do you want to remove "{{name}}" forever?`,
    `คุณต้องการลบ "{{name}}" ถาวรใช่หรือไม่?`,
  ],
  Download: ["Download", "ดาวน์โหลด"],
  Dmatching: [
    "Match the answers to the questions.",
    "ให้จับคู่ตัวเลือกกับคำถามให้ถูกต้อง",
  ],
  Dmultiple: ["Choose to best answer.", "ให้เลือกคำตอบที่ถูกต้องที่สุด"],
  Dsorting: ["Sorting the answer options.", "ให้เรียงลำดับตัวเลือกให้ถูกต้อง"],
  Dtruefalse: [
    "Identify if the following true or false.",
    "ให้เลือกว่าข้อความที่ให้มาถูกหรือผิด",
  ],
  Edit: ["Edit", "แก้ไข"],
  Email: ["Email", "อีเมล"],
  Empty: ["Empty", "ว่างเปล่า"],
  False: ["False", "ผิด"],
  FeatureImage: ["Feature Image", "ภาพปก"],
  File: ["File", "ไฟล์"],
  Filter: ["Filter", "ตัวกรอง"],
  "Forget Password": ["Forget Password", "ลืมรหัสผ่าน"],
  From: ["From", "จาก"],
  "From URL": ["From  URL", "จาก URL"],
  Grading: ["Grading", "ตัดเกรด"],
  Header: ["Header", "หัวเรื่อง"],
  Heading: ["Heading", "หัวเรื่อง"],
  Hide: ["Hide", "ซ่อน"],
  "Hide Toolbar": ["Hide Toolbar", "ซ่อนแถบเครื่องมือ"],
  Highlight: ["Highlight", "ไฮไลท์"],
  Home: ["Home", "หน้าแรก"],
  Image: ["Image", "รูปภาพ"],
  Images: ["Images", "รูปภาพ"],
  "Invalid Email": ["Invalid E-mail", "รูปแบบอีเมลไม่ถูกต้อง"],
  Language: ["Language", "ภาษา"],
  LastModified: ["Last Modified", "ปรับปรุงล่าสุด"],
  Lesson: ["Lesson", "บทเรียน"],
  "Lesson Content": ["Lesson Content", "เนื้อหาบทเรียน"],
  Level: ["Level", "ระดับ"],
  Light: ["Light", "สว่าง"],
  Line: ["Line", "ไลน์"],
  "Line Application": ["Line Application", "แอปพลิเคชันไลน์"],
  Loading: ["Loading", "กำลังโหลด"],
  "No title": ["No title", "ไม่มีชื่อเรื่อง"],
  "Manage Student": ["Manage Student", "จัดการผู้เรียน"],
  "Match Item": ["Match Item", "คู่ที่"],
  Matching: ["Matching", "จับคู่"],
  More: ["More", "อื่น ๆ"],
  "Move Up": ["Move Up", "เลื่อนขึ้น"],
  "Move Down": ["Move Down", "เลื่อนลง"],
  "Multiple Choice": ["Multiple Choice", "หลายตัวเลือก"],
  "My Course": ["My Course", "หลักสูตรของฉัน"],
  Name: ["Name", "ชื่อ"],
  NameHasRemoved: ["{{name}} has removed", "{{name}} ได้ถูกลบแล้ว"],
  $NameNotFound: ["{{name}} not found", "ไม่พบ{{name}}"],
  $NameRemoved: ["{{name}} removed", "ลบ{{name}}แล้ว"],
  "No rows": ["No rows", "ไม่มีข้อมูล"],
  "No Title": ["No Title", "ไม่มีชื่อเรื่อง"],
  Number: ["Number", "ตัวเลข"],
  Organize: ["Organize", "องค์กร"],
  Open: ["Open", "เปิด"],
  Paragraph: ["Paragraph", "ย่อหน้า"],
  Password: ["Password", "รหัสผ่าน"],
  photos: ["photos", "รูป"],
  Preview: ["Preview", "ดูตัวอย่าง"],
  PleaseWait: ["Please wait", "กรุณารอสักครู่"],
  Private: ["Private", "ส่วนตัว"],
  "Profile Image": ["Profile Image", "รูปประจำตัว"],
  Project: ["Project", "โครงการ"],
  Public: ["Public", "สาธารณะ"],
  Question: ["Question", "ข้อสอบ"],
  "Question Bank": ["Question Bank", "คลังแบบทดสอบ"],
  "Question Title": ["Question Title", "ชื่อข้อสอบ"],
  "Question Type": ["Question Type", "ประเภทคำถาม"],
  Quiz: ["Quiz", "แบบทดสอบ"],
  ReadyToEdit: [`Ready to edit "{{name}}"?`, `เริ่มสร้าง "{{name}}" เลยไหม?`],
  "Rows per page": ["Rows per page", "จำนวนแถวต่อหน้า"],
  Read: ["Read", "อ่าน"],
  Register: ["Register", "สมัคร"],
  Remove: ["Remove", "ลบ"],
  Removed: ["Removed", "ลบแล้ว"],
  RemoveLastPoint: ["Remove Last Point", "ลบจุดสุดท้าย"],
  Remove$Name: ["Remove {{name}}", "ลบ{{name}}"],
  Rename: ["Rename", "เปลี่ยนชื่อ"],
  Reverse: ["Reverse", "กลับด้าน"],
  "Right Answer": ["Right answer", "คำตอบที่ถูกต้อง"],
  RowPerPage: ["RowPerPage", "จำนวนแถวต่อหน้า"],
  Save: ["Save", "บันทึก"],
  Saved: ["Saved", "บันทึกสำเร็จ"],
  "Send Email": ["Send E-mail", "ส่งอีเมล"],
  SetAs$Name: ["Set as {{name}}", "ตั้งค่าเป็น{{name}}"],
  Score: ["Score", "คะแนน"],
  Search: ["Search", "ค้นหา"],
  "Select right answer": ["Select right answer", "เลือกคำตอบที่ถูกต้อง"],
  Section: ["Section", "กลุ่ม"],
  Select: ["Select", "เลือก"],
  "Select?": ["Select {{name}}", "เลือก{{name}}"],
  "Select Answer": ["Select Answer", "เลือกคำตอบ"],
  "Send Reset Password": [
    "Send reset password link to {{email}} success",
    "ส่งลิงก์ในการรีเซตรหัสผ่านไปที่ {{email}} สำเร็จ",
  ],
  Sequence: ["Sequence", "ลำดับ"],
  Setting: ["Setting", "ตั้งค่า"],
  "Sign In": ["Sign In", "เข้าสู่ระบบ"],
  "Sign out": ["Sign out", "ออกจากระบบ"],
  SignOut: ["Sign out", "ออกจากระบบ"],
  Sharing: ["Sharing", "แบ่งปัน"],
  Shuffle: ["Shuffle", "สลับ"],
  Slide: ["Slide", "สไลด์"],
  "Show columns": ["Show columns", "แสดงคอลัมน์"],
  Sorting: ["Sorting", "เรียงลำดับ"],
  Spacing: ["Spacing", "ระยะห่าง"],
  Student: ["Student", "ผู้เรียน"],
  "Sub Question": ["Sub Question", "คำถามย่อยที่"],
  Subject: ["Subject", "หมวดหมู่"],
  "Sub Type": ["Sub Type", "ประเภทย่อย"],
  "Student ID": ["Student ID", "รหัสนักเรียน/นิสิต/นักศึกษา"],
  SystemDefault: ["System Default", "ค่าเริ่มต้นของระบบ"],
  Table: ["Table", "ตาราง"],
  "Target Group": ["Target Group", "กลุ่มเป้าหมาย"],
  Task: ["Task", "งาน"],
  Teacher: ["Teacher", "ผู้สอน"],
  Thumbnail: ["Thumbnail", "รูปขนาดย่อ"],
  Title: ["Title", "ชื่อเรื่อง"],
  True: ["True", "ถูก"],
  "True / False": ["True / False", "ถูก / ผิด"],
  Type: ["Type", "ประเภท"],
  Unsort: ["Unsort", "ยกเลิกการเรียง"],
  Untitled: ["Untitled", "ยังไม่มีชื่อ"],
  Update: ["Update", "อัปเดต"],
  Upload: ["Upload", "อัปโหลด"],
  User: ["User", "ผู้ใช้งาน"],
  Value: ["Value", "ค่า"],
  Video: ["Video", "วีดิโอ"],
  VDO: ["VDO", "วีดิโอ"],
  Visibility: ["Visibility", "การมองเห็น"],
  Website: ["Website","เว็บไซต์"],
  Welcome: ["Welcome", "ยินดีต้อนรับ"],
  /**
   * QuizDisplay
   */
  "Choose to best answer": [
    "Choose to best answer.",
    "ให้เลือกคำตอบที่ถูกต้องที่สุด",
  ],
  "Identify if the following true or false": [
    "Identify if the following true or false.",
    "ให้เลือกว่าข้อความที่ให้มาถูกหรือผิด",
  ],
  "Match the answers to the questions": [
    "Match the answers to the questions.",
    "ให้จับคู่ตัวเลือกกับคำถามให้ถูกต้อง",
  ],
  "Sorting the answer options": [
    "Sorting the answer options.",
    "ให้เรียงลำดับตัวเลือกให้ถูกต้อง",
  ],
};

export const getLocale = (lang: string): { [key: string]: string } => {
  const index = ["en", "th"].indexOf(lang);
  if (index > -1) {
    return Object.assign(
      {},
      ...Object.keys(locale_en_th).map((key) => ({
        [key]: locale_en_th[key][index] || null,
      }))
    );
  }
  return {};
};
