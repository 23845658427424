import * as React from "react";
import Box from "@mui/material/Box";
import { ViewQuestionType } from "controllers/course.controller";
import { Button, Pagination, styled } from "@mui/material";
import update from "react-addons-update";
import { grey } from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuizAnswerTypes, QuizDisplay } from "components/core-sub";

const Root = styled(Box)({
  borderRadius: 8,
  border: `solid 1px ${grey[300]}`,
  padding: "1.5rem 1rem 1rem",
});

export type Answers = Record<string, QuizAnswerTypes>;

export const VLinearStepper = (props: {
  docs: ViewQuestionType["docs"];
  onConfirm: (answers: Answers) => void;
}) => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [answers, setAnswers] = React.useState<Answers>({});

  const handleBack = () => {
    setActiveStep((a) => a - 1);
  };
  const handleNext = () => {
    setActiveStep((a) => a + 1);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box display={"flex"} justifyContent="center">
        <Pagination
          count={props.docs.length}
          page={activeStep + 1}
          hidePrevButton
          hideNextButton
        />
      </Box>
      <Box>
        {props.docs.map(
          (doc, index, docs) =>
            index === activeStep && (
              <Root mt={3} key={doc.id}>
                <QuizDisplay
                  value={answers[doc.id]}
                  quiz={doc}
                  onChange={(answer) => {
                    setAnswers((s) =>
                      update(s, { [doc.id]: { $set: answer } })
                    );
                  }}
                />
                <Box display="flex" mt={2}>
                  {index > 0 && <Button onClick={handleBack}>Back</Button>}
                  <Box flex={1} />
                  {docs.length - 1 > index && (
                    <Button
                      variant="contained"
                      startIcon={
                        <FontAwesomeIcon icon={["fad", "chevron-right"]} />
                      }
                      onClick={handleNext}
                      disabled={!Boolean(answers[doc.id])}
                    >
                      Next
                    </Button>
                  )}
                  {docs.length - 1 === index &&
                    props.docs.every((doc) => Boolean(answers[doc.id])) && (
                      <Button
                        variant="contained"
                        startIcon={<FontAwesomeIcon icon={["fad", "check"]} />}
                        onClick={() => props.onConfirm(answers)}
                      >
                        Confirm
                      </Button>
                    )}
                </Box>
              </Root>
            )
        )}
      </Box>
    </Box>
  );
};
