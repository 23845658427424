import {
  Container,
  ContentHeader,
  useAlerts,
  useCore,
} from "components/core-sub";
import {
  Box,
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  styled,
  TextFieldProps,
  SelectChangeEvent,
  Link,
} from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { categories } from "controllers/variables";
import debounce from "lodash.debounce";
import { useCourseEdit } from "Pages/course.edit";
import update from "react-addons-update";

const updateDebounce = debounce((callback: () => void) => {
  callback();
}, 1000);

const TextFieldStyled = styled((props: TextFieldProps) => (
  <TextField fullWidth variant="outlined" {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    marginBottom: theme.spacing(2),
  },
}));

export const PageCourseDesc = () => {
  const { t } = useCore();
  const { addAlert } = useAlerts();
  const { data, setData, id, control } = useCourseEdit();

  const debounceSave = useCallback(updateDebounce, []);

  const handleChange =
    (key: string) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setData((d) => update(d || {}, { [key]: { $set: value } }));
      debounceSave(async () => {
        if (id && control?.course) {
          await control.course.update(id, key, value);
          addAlert({ label: t("Saved") });
        }
      });
    };
  const handleChangeSelect = ({
    target: { value },
  }: SelectChangeEvent<string>) => {
    setData((d) => update(d || {}, { category: { $set: value } }));
    debounceSave(async () => {
      if (id && control?.course) {
        await control.course.update(id, "category", value);
        addAlert({ label: t("Saved") });
      }
    });
  };

  return (
    <Box py={6}>
      <Container maxWidth="post">
        <ContentHeader
          label={t("Detail")}
          breadcrumbs={[
            {
              label: t("Home"),
              component: (
                <Link href="https://mek.network" fontWeight="bold">
                  {t("Home")}
                </Link>
              ),
            },
            { label: t("My Course"), to: "/" },
            { label: data?.title },
            { label: t("Detail") },
          ]}
        />
        <TextFieldStyled
          label={t("Teacher")}
          value={data?.teacher || ""}
          onChange={handleChange("teacher")}
          sx={{ backgroundColor: "background.paper" }}
        />
        <TextFieldStyled
          label={t("Detail")}
          multiline
          rows={5}
          value={data?.desc || ""}
          onChange={handleChange("desc")}
          sx={{ backgroundColor: "background.paper" }}
        />
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ backgroundColor: "background.paper" }}
        >
          <InputLabel id="subject-label">{t("Subject")}</InputLabel>
          <Select
            labelId="subject-label"
            label={t("Subject")}
            value={
              data?.category && categories.includes(data.category)
                ? data.category
                : ""
            }
            onChange={handleChangeSelect}
          >
            <MenuItem value="" disabled>
              -- {t("Subject")} --
            </MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Container>
    </Box>
  );
};
