import {
  BackLink,
  Container,
  ContentHeader,
  DataGrid,
  DebounceTextField,
  KuiActionIcon,
  KuiButton,
  MainContainer,
  QuizDisplay,
  QuizDocument,
  useAlerts,
  useCore,
  usePopup,
} from "components/core-sub";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link as MLink,
  ListItem,
} from "@mui/material";
import {
  CourseController,
  QuestionBankDocument,
} from "controllers/course.controller";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import update from "react-addons-update";

export const PageQuestionBankEdit = () => {
  const { t } = useCore();
  const { addAlert } = useAlerts();
  const { user } = useCore();
  const { id, qid } = useParams();
  const [state, setState] = useState<{
    loading: boolean;
    docs: QuizDocument[];
    question?: QuestionBankDocument;
  }>({
    loading: true,
    docs: [],
  });
  const [view, setView] = useState<string>("");
  const { Popup } = usePopup();
  const nav = useNavigate();

  const getView = () => state.docs.find((doc) => doc.id === view);
  const handleChangeTitle = async (value: string) => {
    if (qid && user.data) {
      const control = new CourseController(user.data);
      await control.questionbank.update(qid, "title", value);
      setState((s) => update(s, { question: { title: { $set: value } } }));
      addAlert({ label: "Update Success." });
    }
  };
  const handleAdd = () => {
    Popup.prompt({
      title: t("AddName", { name: "Question" }),
      text: t("Label"),
      icon: "plus-square",
      onConfirm: async (title) => {
        if (user.data && title && id && qid) {
          const doc = await CourseController.question
            .add(user.data, qid, id, title)
            .catch((err) => {
              addAlert({ label: err.message, severity: "error" });
              return null;
            });
          if (doc?.id) {
            Popup.confirm({
              title: t("Added"),
              text: t("ReadyToEdit", { name: title }),
              icon: "check-circle",
              onConfirm: () => {
                nav(`/course/${id}/question/${qid}/quiz/${doc.id}`);
              },
            });
          }
        }
      },
    });
  };
  const handleRemove = (doc: QuizDocument) => async () => {
    Popup.remove({
      title: t("Remove"),
      text: t("DoYouWantToRemove", { name: doc.title || t("NoTitle") }),
      icon: "trash",
      onConfirm: async () => {
        if (doc.id) {
          await CourseController.question.remove(doc.id);
          addAlert({ label: t("Removed") });
        }
      },
    });
  };

  useEffect(() => {
    if (user.loading === false && user.data && qid) {
      const control = new CourseController(user.data);
      control.questionbank.getOne(qid).then((question) => {
        if (question) {
          setState((s) => ({ ...s, question }));
        }
      });
      return CourseController.question.watch(user.data, qid, (docs) => {
        setState((s) => ({ ...s, loading: false, docs }));
      });
    }
  }, [user, qid]);

  return (
    <MainContainer
      signInOnly
      sidebar={
        <>
          <BackLink divider to={`/course/${id}/question`} />
          <ListItem divider sx={{ pt: 2 }}>
            <DebounceTextField
              fullWidth
              label={t("Title")}
              value={state?.question?.title}
              onChange={handleChangeTitle}
            />
          </ListItem>
        </>
      }
    >
      <Container maxWidth="md">
        <ContentHeader
          label={state?.question?.title}
          breadcrumbs={[
            {
              label: t("Home"),
              component: (
                <MLink href="https://mek.network" fontWeight="bold">
                  {t("Home")}
                </MLink>
              ),
            },
            { label: t("My Course"), to: "/" },
            { label: t("Course") },
            { label: t("Question Bank"), to: `/course/${id}/question` },
            { label: state.question?.title },
          ]}
          actions={<KuiButton tx="add" onClick={handleAdd} />}
        />
        <DataGrid
          loading={state.loading}
          rows={state?.docs}
          columns={[
            {
              field: "action",
              headerName: " ",
              renderCell: ({ row }) => {
                return (
                  <>
                    <Link to={`/course/${id}/question/${qid}/quiz/${row.id}`}>
                      <KuiActionIcon tx="edit" />
                    </Link>
                    <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                  </>
                );
              },
              align: "center",
              width: 64,
            },
            {
              field: "title",
              headerName: t("Title"),
              width: 360,
              renderCell: ({ row, id, value }) => (
                <MLink
                  sx={{ cursor: "pointer" }}
                  onClick={() => setView(id as string)}
                >
                  {value}
                </MLink>
              ),
            },
            {
              field: "datemodified",
              headerName: t("Date"),
              renderCell: ({ value }: { value?: Timestamp }) => {
                return moment(value ? value?.toMillis?.() : Date.now()).format(
                  "L LT"
                );
              },
              width: 160,
            },
          ]}
          disableSelectionOnClick
        />
      </Container>
      {Boolean(getView()) ? (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={Boolean(getView())}
          onClose={() => setView("")}
        >
          <DialogTitle>Preview</DialogTitle>
          <DialogContent>
            <QuizDisplay quiz={getView()!} onChange={() => {}} />
          </DialogContent>
          <DialogActions>
            <KuiButton tx="close" onClick={() => setView("")} />
          </DialogActions>
        </Dialog>
      ) : null}
    </MainContainer>
  );
};
