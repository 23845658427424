import {
  Container,
  ContentHeader,
  KuiActionIcon,
  KuiActionIconProps,
  Sortable,
  useAlerts,
  useCore,
  usePopup,
} from "components/core-sub";
import {
  Box,
  Link as MLink,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { lessonDocument } from "controllers/course.controller";
import { useCourseEdit } from "Pages/course.edit";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ListItemLesson } from "./list.item.lesson";
import { arrayMoveImmutable } from "array-move";
import { AddButton } from "./index.add";
import {
  LessonQuestionContext,
  LessonQuestionEditor,
  QuestionData,
} from "./question";

const QuestionEditorIcon = ({
  onClick,
  ...props
}: Omit<KuiActionIconProps, "tx">) => {
  const { setOpen } = useContext(LessonQuestionContext);

  return (
    <KuiActionIcon
      {...props}
      tx="edit"
      onClick={(event) => {
        setOpen(true);
        onClick?.(event);
      }}
    />
  );
};

export const PageCourseLessonList = () => {
  const { id, control, data } = useCourseEdit();
  const { t } = useCore();
  const { Popup } = usePopup();
  const { addAlert } = useAlerts();
  const navigate = useNavigate();
  const [state, setState] = useState<{
    loading: boolean;
    docs: lessonDocument[];
  }>({
    loading: true,
    docs: [],
  });
  const [qdata, setQData] = useState<undefined | QuestionData>();

  const handleAdd = async () => {
    Popup.prompt({
      title: t("AddName", { name: "Lesson" }),
      text: t("Label"),
      icon: "plus-circle",
      onConfirm: async (value) => {
        if (control && id && value) {
          const result = await control.lesson.add(id, value).catch((err) => {
            addAlert({ label: err.message, severity: "error" });
          });
          if (result?.id) {
            Popup.confirm({
              title: t("Added"),
              text: t("ReadyToEdit", { name: value }),
              icon: "check-circle",
              onConfirm: () => {
                navigate(`/course/${id}/lesson/${result.id}`);
              },
            });
          }
        }
      },
    });
  };
  const handleRemove = (remove: lessonDocument) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("DoYouWantToRemove", { name: remove.title }),
      icon: "trash",
      onConfirm: async () => {
        if (remove && control) {
          await control.lesson.remove(remove.id);
        }
      },
    });
  };
  const handleSortEnd = async ({ oldIndex, newIndex }: any) => {
    if (id && control) {
      const docs = arrayMoveImmutable(state.docs, oldIndex, newIndex);
      setState((s) => ({ ...s, docs }));
      await control.lesson.sorting(id, docs);
      addAlert({ label: "Updated" });
    }
  };

  useEffect(() => {
    document.title = `Lesson | ${process.env.REACT_APP_SITE_NAME}`;
    if (control && id) {
      return control.lesson.watchMany(id, (docs) =>
        setState((s) => ({ ...s, loading: false, docs }))
      );
    }
  }, [control, id]);

  return id ? (
    <LessonQuestionEditor data={qdata} clearAfterSave>
      <Box py={6}>
        <Container maxWidth="post">
          <ContentHeader
            label={t("Lesson")}
            breadcrumbs={[
              {
                label: t("Home"),
                component: (
                  <MLink href="https://mek.network" fontWeight="bold">
                    {t("Home")}
                  </MLink>
                ),
              },
              { label: t("My Course"), to: "/" },
              { label: data?.title },
              { label: t("Lesson") },
            ]}
            actions={
              <>
                <AddButton onAddLesson={handleAdd} />
                {/* <ImportLesson parent={id} /> */}
              </>
            }
          />
          <List disablePadding>
            <Sortable
              divider
              docs={state.docs}
              onSortEnd={handleSortEnd}
              component={(doc, index) => {
                switch (doc.type) {
                  case `question`:
                  case `question-private`:
                    return (
                      <ListItemLesson
                        id={id}
                        doc={doc}
                        index={index}
                        key={doc.id}
                        onRemove={handleRemove(doc)}
                        actions={
                          <QuestionEditorIcon onClick={() => setQData(doc)} />
                        }
                      />
                    );
                  case `subjective`:
                  case `subjective-private`:
                    return (
                      <ListItemLesson
                        id={id}
                        doc={doc}
                        index={index}
                        key={doc.id}
                        onRemove={handleRemove(doc)}
                      />
                    );
                  default:
                    return (
                      <ListItemLesson
                        id={id}
                        doc={doc}
                        index={index}
                        key={doc.id}
                        onRemove={handleRemove(doc)}
                        actions={
                          <>
                            <Link to={`/course/${id}/lesson/${doc.id}`}>
                              <KuiActionIcon tx="edit" />
                            </Link>
                          </>
                        }
                      />
                    );
                }
              }}
            />
            {state.docs.length === 0 && (
              <ListItem divider>
                <ListItemText secondary={"No item"} />
              </ListItem>
            )}
          </List>
        </Container>
      </Box>
    </LessonQuestionEditor>
  ) : (
    <></>
  );
};
