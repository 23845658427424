import { MainContainer, StockDisplay, useCore } from "components/core-sub";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Route, Routes, useParams } from "react-router-dom";
// import { QuestionBank } from "../QuestionBank";
// import { PageCourseDesc } from "../CourseDesc";
// import { connectCourseEdit, CourseEditContext } from "./context";
import { Sidebar } from "./sidebar";
// import { PageLesson } from "../Lesson";
import { CourseController, courseTypes } from "controllers/course.controller";
import { PageCourseDesc } from "Pages/course.desc";
import { PageCourseLessonList } from "Pages/course.lesson.list";
import { PageCourseQuestionList } from "Pages/course.question.list";
import {PageCourseSyllabusView} from "Pages/course.syllabus.view";
import { PageSection } from "Pages/course.section";

interface StateTypes {
  loading: boolean;
}
interface CourseEditContextTypes {
  id?: string;
  tab?: string;
  control: CourseController | null;
  data: courseTypes | null;
  setData: Dispatch<SetStateAction<courseTypes | null>>;
  state: StateTypes;
  setState: Dispatch<SetStateAction<StateTypes>>;
}
const CourseEditContext = createContext<CourseEditContextTypes>({
  control: null,
  data: null,
  setData: () => {},
  state: {
    loading: true,
  },
  setState: () => {},
});
export const useCourseEdit = () => useContext(CourseEditContext);

export const PageCourseEdit = () => {
  const { id, "*": tab } = useParams();
  const { user, isMobile } = useCore();
  const [control, setControl] = useState<CourseController | null>(null);
  const [state, setState] = useState<StateTypes>({
    loading: false,
  });
  const [data, setData] = useState<courseTypes | null>(null);

  useEffect(() => {
    document.title = `Edit Course | ${process.env.REACT_APP_SITE_NAME}`;

    if (user.loading === false && user.data && id) {
      const control = new CourseController(user.data);
      setControl(control);
      return control.course.watchOne(id, (data) => {
        setState((s) => ({ ...s, fetched: true }));
        setData(data);
      });
    }
  }, [user, id]);

  return (
    <CourseEditContext.Provider
      value={{
        id,
        tab,
        control,
        data,
        setData,
        state,
        setState,
      }}
    >
      <MainContainer dense signInOnly sidebar={<Sidebar />}>
        {Boolean(data?.feature) && (
          <StockDisplay {...data?.feature} ratio={isMobile?1:1/4} size="large" />
        )}
        <Routes>
          <Route path="/section" element={<PageSection />} />
          {/*<Route path="/edit/:id/question" component={QuestionBank} />*/}
          {/* <Route path="/syllabus" element={<PageCourseSyllabus />} /> */}
          <Route path="/syllabus" element={<PageCourseSyllabusView />} />
          <Route path="/question" element={<PageCourseQuestionList />} />
          <Route path="/lesson" element={<PageCourseLessonList />} />
          <Route path="/*" element={<PageCourseDesc />} />
        </Routes>
      </MainContainer>
    </CourseEditContext.Provider>
  );
};
