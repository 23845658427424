import {
  Container,
  ContentHeader,
  DataGrid,
  MainContainer,
  KuiActionIcon,
  useCore,
  useAlerts,
  KuiButton,
  StockDisplayProps,
  VisibilityTabs,
  VisibilityTabsValue,
  usePopup,
} from "../../components/core-sub";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Link as MLink } from "@mui/material";
import { stateTypes } from "./index.d";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faLock } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";

import { CourseController, courseTypes } from "controllers/course.controller";
// import { cloneCourseController } from "./controller.clone";

const visibilities = {
  public: "course",
  private: "course-private",
  trash: "course-remove",
};

const getFeature = (image?: StockDisplayProps): string | undefined => {
  if (image?.image?._id) {
    return `https://s1.phra.in:8086/file/id/${image.image._id}/thumbnail`;
  }
  return undefined;
};

export const PageCourse = () => {
  const { user, t } = useCore();
  const { Popup } = usePopup();
  const { addAlert } = useAlerts();
  const [state, setState] = useState<stateTypes>({
    loading: true,
    docs: [],
  });
  const [controller, setController] = useState<CourseController | null>(null);
  const [tab, setTab] = useState<VisibilityTabsValue>("public");
  const nav = useNavigate();

  const handleAdd = async () => {
    Popup.prompt({
      title: t("AddName", { name: "Course" }),
      text: t("Title"),
      icon: "plus-circle",
      onConfirm: async (value) => {
        if (controller?.add && value) {
          const result = await controller.add(value).catch((err) => {
            addAlert({ label: `${err.message}`, severity: "error" });
          });
          if (result?.id) {
            Popup.confirm({
              title: t("Added"),
              text: t("ReadyToEdit", { name: value }),
              icon: "check-circle",
              onConfirm: () => {
                nav(`/course/${result.id}`);
              },
            });
          }
        }
      },
    });
  };
  // const handleDuplicate =
  //   (doc: courseTypes | null, list: courseTypes[] | null) => async () => {
  //     //----------Count-Duplicate-Data----------//
  //     const countArray = list?.filter(({ title }: string | any) =>
  //       title.includes(doc?.title)
  //     ).length;
  //     //----------Count-Duplicate-Data----------//

  //     //----------clone-child-Data----------//
  //     if (doc?.id) {
  //       const control = new cloneCourseController();
  //       const lessons = await control.lessonsList(doc.id);
  //       const questionsBank = await control.questionsList(doc.id);
  //       const questionsQuery = await Promise.all(
  //         questionsBank.map(async (bank) => {
  //           const quiz = await control.question(bank.id, doc.id);
  //           return quiz;
  //         })
  //       );
  //       //----------clone-child-Data----------//
  //       Popup.confirm({
  //         title: "Duplicate Course",
  //         text: t("Do you want to duplicate", {
  //           name: doc?.title || "",
  //         }),
  //         icon: "copy",
  //         onConfirm: async () => {
  //           console.log(countArray, lessons, questionsBank, questionsQuery);
  //         },
  //       });
  //     }
  //   };
  const handleRemove = (remove: courseTypes | null) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("DoYouWantToRemove", { name: remove?.title! }),
      icon: "trash",
      onConfirm: async () => {
        if (controller?.remove && remove?.id) {
          await controller.remove(remove.id);
        }
      },
    });
  };
  const handleRestore = (id: string) => () => {
    if (controller) {
      controller.course.restoreBin(id);
    }
  };
  const handleRemoveBin = (doc: courseTypes) => () => {
    if (controller && doc) {
      Popup.remove({
        title: t("Remove"),
        text: t("DoYouWantToRemoveNameForever", { name: doc.title }),
        icon: "trash",
        onConfirm: async () => {
          controller.course.removeBin(doc.id);
        },
      });
    }
  };

  useEffect(() => {
    document.title = `My Course | ${process.env.REACT_APP_SITE_NAME}`;
    if (user.loading === false && user.data) {
      const control = new CourseController(user.data);
      setController(control);
      control.course.watch((docs) =>
        setState((s) => ({ ...s, docs, loading: false }))
      );
    } else {
      setState((s) => ({ ...s, docs: [], loading: true }));
    }
  }, [user]);

  return (
    <MainContainer signInOnly>
      <Container maxWidth="md">
        <ContentHeader
          label={t("My Course")}
          breadcrumbs={[
            {
              label: t("Home"),
              component: (
                <MLink fontWeight={"bold"} href="https://mek.network">
                  {t("Home")}
                </MLink>
              ),
            },
            { label: t("My Course") },
          ]}
          actions={<KuiButton tx="add" onClick={handleAdd} />}
        />
        <VisibilityTabs
          value={tab}
          onChange={setTab}
          count={{
            public: state.docs.filter(
              (doc) => doc.type === visibilities["public"]
            ).length,
            private: state.docs.filter(
              (doc) => doc.type === visibilities["private"]
            ).length,
            trash: state.docs.filter(
              (doc) => doc.type === visibilities["trash"]
            ).length,
          }}
        />
        <DataGrid
          getRowId={(row) => row.id!}
          loading={state.loading}
          rows={state?.docs.filter((doc) => doc.type === visibilities[tab])}
          columns={[
            {
              field: "action",
              headerName: " ",
              renderCell: ({ row }) => {
                return row.type === "course-remove" ? (
                  <>
                    <KuiActionIcon
                      tx="restore"
                      onClick={handleRestore(row.id)}
                    />
                    <KuiActionIcon tx="remove" onClick={handleRemoveBin(row)} />
                  </>
                ) : (
                  <>
                    {/* <KuiActionIcon
                      tx="copy"
                      onClick={handleDuplicate(row, null)}
                    /> */}
                    <KuiActionIcon
                      tx="edit"
                      onClick={() => nav(`/course/${row.id}`)}
                    />
                    <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                  </>
                );
              },
              align: "center",
              width: 64,
            },
            {
              field: "feature",
              headerName: " ",
              renderCell: ({ row }) => {
                return row.feature ? (
                  <Avatar variant="square" src={getFeature(row?.feature)} />
                ) : (
                  <Avatar variant="square">
                    <FontAwesomeIcon icon={faImage} />
                  </Avatar>
                );
              },
              width: 60,
            },
            {
              field: "title",
              headerName: t("Title"),
              width: 360,
              renderCell: ({ row }) => (
                <MLink component={Link} to={`/view/${row.id}`} target="_blank">
                  {row.type.includes("private") && (
                    <FontAwesomeIcon
                      icon={faLock}
                      style={{ marginRight: "0.25em" }}
                    />
                  )}
                  {row.title}
                </MLink>
              ),
            },
            {
              field: "datemodified",
              headerName: t("Date"),
              renderCell: ({ value }) => {
                return moment(value?.toMillis?.() || Date.now()).format("L LT");
              },
              width: 180,
            },
          ]}
          disableSelectionOnClick
        />
      </Container>
    </MainContainer>
  );
};
