import { PageViewer, useCore } from "components/core-sub";
import { Link } from "@mui/material";
import { CourseController, courseTypes, getSyllabus } from "controllers/course.controller";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCourseEdit } from "Pages/course.edit";

export const PageCourseSyllabusView = () => {
  const { user, t } = useCore();
  const { id } = useParams();
  const [course,setCourse] = useState<undefined | courseTypes>()
  const { data } = useCourseEdit()

  useEffect(() => {
    document.title = `Edit Course | ${process.env.REACT_APP_SITE_NAME}`;

    if (user.loading === false && user.data && id) {
      const control = new CourseController(user.data);
      return control.course.watchOne(id, (doc) => setCourse(doc));
    }
  }, [user, id]);

  return course ? (
    <PageViewer
      data={getSyllabus(course)}
      breadcrumbs={[
        {
          label: "Home",
          component: (
            <Link fontWeight={"bold"} href="https://mek.network">
              {t("Home")}
            </Link>
          ),
        },
        { label: t("My Course"), to: "/" },
        { label: data?.title },
        { label: t("Syllabus") },
      ]}
      noContainer
    />
  ) : null;
};
