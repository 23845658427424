import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/material";
import { QuizAnswer, quizAnswerCheck, useCore } from "components/core-sub";
import { ViewQuestionType } from "controllers/course.controller";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Answers } from "./stepper";

export const Solved = (props: {
  docs: ViewQuestionType["docs"];
  answers: Answers;
}) => {
  const { t } = useCore();
  const { courseId } = useParams();
  const nav = useNavigate();

  const scoreCalc = (): number =>
    props.docs.filter((doc) => quizAnswerCheck(doc, props.answers[doc.id]))
      .length;

  return (
    <Fragment>
      <Typography variant="h4" textAlign="center" paragraph>
        {t("Your Score")}: {scoreCalc()}/{props.docs.length}
      </Typography>
      <Box textAlign="center">
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={["fad", "chevron-left"]} />}
          onClick={() => nav(`/view/${courseId}`)}
        >
          Back
        </Button>
      </Box>
      {props.docs.map((doc, index) => (
        <Box mt={index > 0 ? 3 : 6} key={doc.id}>
          <QuizAnswer quiz={doc} answer={props.answers[doc.id]} />
        </Box>
      ))}
    </Fragment>
  );
};
