import { faCalendar } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KuiActionIcon, KuiButton, Schedule, useCore } from "components/core-sub";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Slide,
  SlideProps,
} from "@mui/material";
import { AutomateTypes, CourseController } from "controllers/course.controller";
import moment from "moment";
import { forwardRef, Fragment, useEffect, useState } from "react";

const labels:Record<string,string> = {
  "course-private": "Private",
  "course": "Public",
};

interface SelectVisibilityProps {
  id?: string;
  value?: string;
  prefix: string;
  onChange: (value: string) => void;
}
export const SelectVisibility = forwardRef(
  ({ id, value: oldValue, onChange, prefix }: SelectVisibilityProps, _ref) => {
    const { t, user } = useCore();
    const [control, setControl] = useState<CourseController | null>(null);

    const [open, setOpen] = useState<boolean>(false);
    const [scheduleOpen, setScheduleOpen] = useState<boolean>(false);
    const [value, setValue] = useState("private");
    const [auto, setAuto] = useState<AutomateTypes | null>(null);
    const completed = Boolean(value !== oldValue);

    const handleOpen = (o: boolean) => () => setOpen(o);
    const handleChange = ({ target: { value } }: SelectChangeEvent<string>) =>
      setValue(value);
    const handleConfirm = () => {
      onChange(value);
      setOpen(false);
    };
    const handleSchedule = async (time: string) => {
      if (id && user?.data?.uid && control) {
        const data: AutomateTypes = {
          parent: id,
          path: [{ collection: "courses", doc: id }],
          time,
          epoch: new Date(time).getTime(),
          type: "update",
          user: user.data.uid,
          value: {
            type: value,
          },
        };
        await control.automate.add(data);
      }
    };
    const handleCancelSchedule = async () => {
      if (auto?.id && user.data && control) {
        const control = new CourseController(user.data);
        await control.automate.remove(auto.id);
      }
    };

    useEffect(() => {
      setValue(oldValue || `private`);
      if (user.loading === false && user.data && id) {
        const control = new CourseController(user.data);
        setControl(control);
        return control.automate.watch(id, (auto) => setAuto(auto));
      }
    }, [user, id, oldValue, prefix]);

    return (
      <List>
        <ListItem divider dense>
          <ListItemText
            primary={t("Visibility")}
            secondary={
              oldValue
                ? t(labels?.[oldValue] || "private")
                : t("Private") || <Skeleton width="50%" />
            }
            primaryTypographyProps={{
              variant: "caption",
              color: "textSecondary",
            }}
            secondaryTypographyProps={{
              variant: "body2",
              color: "textPrimary",
            }}
          />
          <ListItemSecondaryAction>
            <KuiActionIcon tx="edit" onClick={handleOpen(true)} />
          </ListItemSecondaryAction>
        </ListItem>
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={handleOpen(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: "right" } as SlideProps}
        >
          <DialogTitle>{t("Change")}</DialogTitle>
          <DialogContent>
            {auto ? (
              <ListItem
                dense
                component={"div"}
                style={{
                  border: "solid 1px rgba(0,0,0,0.12)",
                  borderRadius: 8,
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCalendar} size="2x" />
                </ListItemIcon>
                <ListItemText
                  primary={t("ChangeAt", {
                    name: auto.value.type,
                    date: moment(auto.epoch).format("ll LT"),
                  })}
                />
              </ListItem>
            ) : (
              <Select
                fullWidth
                variant="outlined"
                value={value}
                onChange={handleChange}
              >
                <MenuItem value="course">{t("Public")}</MenuItem>
                <MenuItem value="course-private">{t("Private")}</MenuItem>
              </Select>
            )}
          </DialogContent>
          <DialogActions>
            {auto ? (
              <Button color="secondary" onClick={handleCancelSchedule}>
                {t("Cancel")}
              </Button>
            ) : (
              <Fragment>
                <Button
                  disabled={!completed}
                  onClick={() => setScheduleOpen(true)}
                >
                  {t("Schedule")}
                </Button>
                <Schedule
                  open={scheduleOpen}
                  onChange={handleSchedule}
                  onClose={() => setScheduleOpen(false)}
                />
                <Box flex={1} />
                <Button
                  color="primary"
                  disabled={!completed}
                  onClick={handleConfirm}
                >
                  {t("Save")}
                </Button>
              </Fragment>
            )}

            <KuiButton tx="close" onClick={handleOpen(false)} />
          </DialogActions>
        </Dialog>
      </List>
    );
  }
);
