import { Box } from "@mui/material";
import {
  ActionIcon,
  Container,
  ContentHeader,
  DataGrid,
  DataGridRowsProp,
  KuiActionIcon,
  KuiButton,
  useCore,
  usePopup,
} from "components/core-sub";
import { AddStudents } from "./add.student";

export const PageSectionStudent = () => {
  const { t } = useCore();
  const { Popup } = usePopup();
  const rows: DataGridRowsProp = [
    { id: 1, col1: "Test", col2: "Test Data #1" },
  ];

  const handleBrowse = async () => {
    Popup.prompt({
      title: "Import File",
      text: "Student Name",
      icon: "plus",
      onConfirm: (value) => {
        console.log(value);
      },
    });
  };
  return (
    <Box py={6}>
      <Container maxWidth="post">
        <ContentHeader
          label={t("Student")}
          breadcrumbs={[
            { label: t("Section"), to: "/" },
            { label: t("Courses.MyCourse"), to: `/` },
          ]}
          actions={
            <>
              <KuiButton tx="browse" onClick={handleBrowse}>import</KuiButton>
              &nbsp;
              <AddStudents />
            </>
          }
        />
        <DataGrid
          rows={rows}
          columns={[
            {
              field: "action",
              headerName: "Action",
              width: 64,
              align: "center",
              renderCell: ({ row }) => {
                if (row.visibility === "trash") {
                  return (
                    <>
                      <ActionIcon icon={["fad", "redo"]} />
                      <KuiActionIcon tx="remove" />
                    </>
                  );
                }
                return (
                  <>
                    <KuiActionIcon tx="edit" />
                    <KuiActionIcon tx="remove" />
                  </>
                );
              },
            },
          ]}
        />
      </Container>
    </Box>
  );
};
