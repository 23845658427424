import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const bhaoneConfig = {
  apiKey: "AIzaSyAY-E_1Qv84kM2aCqzgmxQzcsYSz6rGxr8",
  authDomain: "bha-one.firebaseapp.com",
  databaseURL: "https://bha-one.firebaseio.com",
  projectId: "bha-one",
  storageBucket: "bha-one.appspot.com",
  messagingSenderId: "418097845572",
  appId: "1:418097845572:web:71867e35a83118fb2f3311",
};

export const phrainConfig = {
  apiKey: "AIzaSyDXDkEkLCv4y77jfXlQw4bgwUjLAidf3t4",
  authDomain: "phra-in.firebaseapp.com",
  databaseURL:
    "https://phra-in-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "phra-in",
  storageBucket: "phra-in.appspot.com",
  messagingSenderId: "557901104441",
  appId: "1:557901104441:web:c9c0affac3d4e4deb3d4e8",
  measurementId: "G-JM03RN1CM2",
};

const phrainApp = initializeApp(phrainConfig);
const bhaoneApp = initializeApp(bhaoneConfig, 'bhaone');
const db = getFirestore(phrainApp);

export { bhaoneApp, phrainApp, db };
