import {
  Container,
  ContentHeader,
  KuiActionIcon,
  useCore,
  usePopup,
} from "components/core-sub";
import { Box, Link as MLink } from "@mui/material";
import {
  CourseController,
  QuestionBankDocument,
} from "controllers/course.controller";
import { useCourseEdit } from "Pages/course.edit";
import { useEffect, useState } from "react";
import { AddButton } from "./index.add";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { Link } from "react-router-dom";
import { cloneQuestionController } from "./controller.clone";

export const PageCourseQuestionList = () => {
  const { id, control, data } = useCourseEdit();
  const { t, user } = useCore();
  const { Popup } = usePopup();

  const [state, setState] = useState<{
    loading: boolean;
    docs: QuestionBankDocument[];
  }>({
    loading: true,
    docs: [],
  });

  const handleRemove = (doc: QuestionBankDocument) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("DoYouWantToRemove", { name: doc.title }),
      icon: "trash",
      onConfirm: () => {
        if (user.data) {
          const control = new CourseController(user.data);
          control.questionbank.remove(doc.id);
        }
      },
    });
  };

  const handleDuplicate =
    (doc: QuestionBankDocument, list: QuestionBankDocument[]) => async () => {
      const countArray = list?.filter(({ title }: string | any) =>
        title.includes(doc?.title)
      ).length;
      if (doc?.id) {
        const control = new cloneQuestionController();
        const quiz = await control.quizList(doc.id);
        //----------clone-child-Data----------//
        Popup.confirm({
          title: "Duplicate Course",
          text: t("Do you want to duplicate", {
            name: doc?.title || "",
          }),
          icon: "copy",
          onConfirm: async () => {
            console.log(countArray, quiz);
          },
        });
      }
    };

  useEffect(() => {
    if (control && id) {
      return control.questionbank.watchMany(id, (docs) =>
        setState((s) => ({ ...s, loading: false, docs }))
      );
    }
  }, [control, id]);

  return id ? (
    <Box py={6}>
      <Container maxWidth="post">
        <ContentHeader
          label={t("Question")}
          breadcrumbs={[
            {
              label: t("Home"),
              component: (
                <MLink href="https://mek.network" fontWeight="bold">
                  {t("Home")}
                </MLink>
              ),
            },
            { label: t("My Course"), to: "/" },
            { label: data?.title },
            { label: t("Question Bank") },
          ]}
          actions={<AddButton />}
        />
        <DataGrid
          loading={state.loading}
          rows={state?.docs}
          columns={[
            {
              field: "action",
              headerName: " ",
              renderCell: ({ row }) => {
                return (
                  <>
                    <KuiActionIcon
                      tx="copy"
                      onClick={handleDuplicate(row, state.docs)}
                    />
                    <Link to={`/course/${id}/question/${row.id}`}>
                      <KuiActionIcon tx="edit" />
                    </Link>
                    <KuiActionIcon tx="remove" onClick={handleRemove(row)} />
                  </>
                );
              },
              align: "center",
              width: 96,
            },
            {
              field: "title",
              headerName: t("Title"),
              width: 360,
            },
            {
              field: "datemodified",
              headerName: t("Date"),
              renderCell: ({ value }) => {
                return moment(value ? value?.toMillis?.() : Date.now()).format(
                  "L LT"
                );
              },
              width: 160,
            },
          ]}
          autoHeight
          disableSelectionOnClick
        />
      </Container>
    </Box>
  ) : (
    <></>
  );
};
