import { KuiButton, useAlerts, useCore, usePopup } from "components/core-sub";
import { useCourseEdit } from "Pages/course.edit";
import { useNavigate } from "react-router-dom";

export const AddButton = () => {
  const { id, control } = useCourseEdit();
  const { t } = useCore();
  const { addAlert } = useAlerts();
  const { Popup } = usePopup();
  const navigate = useNavigate();

  const handleAddQuestionBank = async () => {
    if (control && id) {
      Popup.prompt({
        title: t("AddName", { name: "Quiz" }),
        text: t("Label"),
        icon: "plus-circle",
        onConfirm: async (value) => {
          if (value) {
            const result = await control.questionbank
              .add(id, value)
              .catch((err) => {
                addAlert({ label: err.message, severity: "error" });
              });
            if (result?.id) {
              Popup.confirm({
                title: t("Added"),
                text: t("ReadyToEdit", { name: value }),
                icon: "check-circle",
                onConfirm: () => {
                  navigate(`/course/${id}/question/${result.id}/`);
                },
              });
            }
          }
        },
      });
    }
  };

  return <KuiButton tx="add" onClick={handleAddQuestionBank} />;
};
