import {
  faChalkboard,
  faFileAlt,
  faListOl,
  faLock,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KuiActionIcon, SortListItem, useCore } from "components/core-sub";
import { ListItemSecondaryAction, ListItemText } from "@mui/material";
import { LinkUnstyled } from "components/link.unstyled";
import { lessonDocument } from "controllers/course.controller";
import moment from "moment";
import { ReactNode } from "react";

const getIcon = (type: string) => {
  switch (type) {
    case `question`:
    case `question-private`:
      return <FontAwesomeIcon icon={faListOl} />;
    case `subjective`:
    case `subjective-private`:
      return <FontAwesomeIcon icon={faFileAlt} />;
    default:
      return <FontAwesomeIcon icon={faChalkboard} />;
  }
};

export interface ListItemLessonProps {
  id: string;
  index: number;
  doc: lessonDocument;
  actions?: ReactNode;
  onRemove: () => void;
}
export const ListItemLesson = ({
  id,
  index,
  doc,
  actions,
  onRemove,
}: ListItemLessonProps) => {
  const { t } = useCore();
  const stringDate = doc?.datemodified?.toMillis
    ? doc?.datemodified?.toMillis()
    : Date.now();

  return (
    <SortListItem index={index} listItemProps={{ divider: true, dense: true }}>
      <ListItemText
        primary={
          <LinkUnstyled
            to={`/view/${id}/${doc.type}/${doc.id}/rd/${encodeURIComponent(`/course/${id}/lesson`)}`}
          >
            {getIcon(doc.type)}
            &nbsp;&nbsp;
            {doc.visibility === "private" && (
              <FontAwesomeIcon icon={faLock} style={{ marginRight: 8 }} />
            )}
            {doc.title}
          </LinkUnstyled>
        }
        secondary={t("LastModified") + " " + moment(stringDate).format("LL LT")}
        secondaryTypographyProps={{ variant: "caption" }}
      />
      <ListItemSecondaryAction>
        {actions}
        <KuiActionIcon tx="remove" onClick={onRemove} />
        {/* <LessonClone id={doc.id} /> */}
        {/* <QuizEdit data={doc}><ActionIcon icon={faEdit} color="primary" /></QuizEdit> */}
        {/* <ActionIcon color="secondary" icon={faTrash} onClick={onRemove} /> */}
      </ListItemSecondaryAction>
    </SortListItem>
  );
};
