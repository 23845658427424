

export const PageSectionChat = () => {

    return (
      <>
       CHAT
      </>
    );
  };
  