import {
  Container,
  ContentHeader,
  useCore,
  KuiButton,
  DataGrid,
  ActionIcon,
  KuiActionIcon,
  DataGridRowsProp,
  usePopup,
} from "components/core-sub";
import { Box } from "@mui/material";
import "moment/locale/th";
import { useCourseEdit } from "Pages/course.edit";

export const PageSection = () => {
  const { t } = useCore();
  const { id } = useCourseEdit();
  const { Popup } = usePopup();

  const handleAdd = async () => {
    Popup.prompt({
      title: "Add Section",
      text: "Section Name",
      icon: "plus",
      onConfirm: (value) => {
        console.log(value);
      },
    });
  };
  const rows: DataGridRowsProp = [
    { id: 1, col1: "Test", col2: "Test Data #1" },
  ];

  return (
    <Box py={6}>
      <Container maxWidth="post">
        <ContentHeader
          label={t("Section")}
          breadcrumbs={[
            { label: t("Home"), to: "/" },
            { label: t("Courses.MyCourse"), to: `/` },
            {
              label: t("Sectionedit"),
              to: `/course/${id}/section/sectionid/student`,
            },
          ]}
          actions={<KuiButton tx="add" onClick={handleAdd} />}
        />
        <DataGrid
          rows={rows}
          columns={[
            {
              field: "action",
              headerName: "Action",
              width: 64,
              align: "center",
              renderCell: ({ row }) => {
                if (row.visibility === "trash") {
                  return (
                    <>
                      <ActionIcon icon={["fad", "redo"]} />
                      <KuiActionIcon tx="remove" />
                    </>
                  );
                }
                return (
                  <>
                    <KuiActionIcon tx="edit" />
                    <KuiActionIcon tx="remove" />
                  </>
                );
              },
            },
          ]}
        />
      </Container>
    </Box>
  );
};
