import { PageDocument, PageEdit, useCore } from "components/core-sub";
import { Breakpoint } from "@mui/material";
import { CourseController } from "controllers/course.controller";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const PageLessonEdit = () => {
  const { user } = useCore();
  const { id, lid } = useParams();
  const [data, setData] = useState<PageDocument>({});
  const [control, setControl] = useState<CourseController | null>(null);

  const handleSave = async () => {
    if (control && lid) {
      control.lesson.updateModified(lid, data);
    }
    return true;
  };

  useEffect(() => {
    document.title = `Lesson | ${process.env.REACT_APP_SITE_NAME}`;
    if (user.loading === false && user.data && lid) {
      const control = new CourseController(user.data);
      setControl(control);
      return control.lesson.watchById(lid, (doc) => {
        // setState((s) => ({ ...s, docs, fetched: true }));
        setData(doc);
      });
    }
  }, [user, lid]);

  return (
    <PageEdit
      data={data}
      setData={setData}
      show={[
        "title",
        "feature",
        "heading",
        "image",
        "paragraph",
        "video",
        "table",
        "visibility"
      ]}
      onSave={handleSave}
      back={`/course/${id}/lesson`}
      maxWidth={"post" as Breakpoint} // xs, sm, post, md, lg, xl
      breadcrumbs={[
        { label: "Home", to: "/" },
        { label: "Course", to: `/course/${id}/lesson` },
        { label: `${data.title || "No Title"}` },
      ]}
    />
  );
};
