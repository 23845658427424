import { DialogPre, KuiButton } from "components/core-sub";

export const GradeSetting = () => {
  return (
    <DialogPre
      button={<KuiButton tx="add" />}
      title="Setting"
      maxWidth={"sm"} // xs, sm, md, lg, xl
      // onConfirm={handleConfirm}
      // open={defaultOpen}
      // confirmButtonProps={ButtonProps}
      secondaryActions={<KuiButton tx="add" />}
    >
      xx
    </DialogPre>
  );
};
