import { List, ListItemButton, ListItemText } from "@mui/material";
import { BackLink, TitleEdit, useCore } from "components/core-sub";
import { useNavigate, useParams } from "react-router-dom";

export const Sidebar = (props:{id?:string}) => {
    const { t } = useCore();
    const { id, "*":tab } = useParams();
    const nav = useNavigate();
    console.log({props, tab})
  return (
    <>
      <BackLink to={`/course/${id}/section`} divider />
      <TitleEdit value="Title" onChange={() => true} />
      <List disablePadding>
        {[
          { label: t("Student"), key: "student" },
          { label: t("Class"), key: "class" },
          { label: t("Grading"), key: "grading" },
          { label: t("Chat"), key: "chat" },
        ].map((item, index) => (
          <ListItemButton
            divider
            dense
            selected={tab === item.key || (index === 0 && !tab)}
            key={item.key}
          >
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{ variant: "body2" }}
              onClick={() => nav(`/course/${id}/section/sectionid/${item.key}`)}
            />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};
