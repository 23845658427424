import * as React from "react";
import { useParams } from "react-router-dom";
import {
  CourseController,
  ViewQuestionType,
} from "controllers/course.controller";
import { Link } from "@mui/material";
import { Answers, VLinearStepper } from "./stepper";
import { Solved } from "./solved";
import { Container, ContentHeader, MainContainer, useCore } from "components/core-sub";

export const ViewQuestion = () => {
  const { user, t } = useCore();
  const { courseId, questionId, redirect } = useParams();
  const [state, setState] = React.useState<{
    loading: boolean;
    data: null | ViewQuestionType;
  }>({
    loading: true,
    data: null,
  });
  const [answers, setAnswers] = React.useState<Answers | null>(null);

  React.useEffect(() => {
    if (user.loading === false && user.data && questionId) {
      const control = new CourseController(user.data);
      control.view
        .question(questionId)
        .then((data) => setState((s) => ({ ...s, data, loading: false })));
    }
  }, [user, questionId, redirect]);

  return (
    <MainContainer loading={state.loading}>
      <Container maxWidth="post">
        <ContentHeader
          breadcrumbs={[
            {
              label: t("Home"),
              component: (
                <Link href="https://mek.network" fontWeight="bold">
                  {t("Home")}
                </Link>
              ),
            },
            { label: t("Course"), to: `/view/${courseId}/` },
            { label: state.data?.title },
          ]}
          label={state.data?.title}
        />
        {answers ? (
          <Solved docs={state.data?.docs || []} answers={answers} />
        ) : (
          <VLinearStepper
            docs={state.data?.docs || []}
            onConfirm={setAnswers}
          />
        )}
      </Container>
    </MainContainer>
  );
};
