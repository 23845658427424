import { KuiButton, useCore } from "components/core-sub";
import { useState } from "react";
import { List, ListItemButton, ListItemText, Menu } from "@mui/material";
// import { AddButtonQuestionBlank } from "./index.add.questionblank";
import { LessonQuestionEditor, MenuItemQuestion } from "./question";

interface AddButtonType {
  onAddLesson: any;
}

export const AddButton = ({ onAddLesson }: AddButtonType) => {
  const { t } = useCore();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <LessonQuestionEditor clearAfterSave>
      <KuiButton tx="add" onClick={handleClick} />
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List>
          <ListItemButton
            dense
            onClick={() => {
              onAddLesson();
              setAnchorEl(null);
            }}
          >
            <ListItemText primary={t("Lesson")} />
          </ListItemButton>
        </List>
        {/* <AddButtonQuestionBlank /> */}
        <MenuItemQuestion onClick={() => setAnchorEl(null)} />
      </Menu>
    </LessonQuestionEditor>
  );
};
