import { cleanObject, PageDocument, PageEdit, useCore } from "components/core-sub";
import { Breakpoint } from "@mui/material";
import { CourseController } from "controllers/course.controller";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const PageCourseSyllabus = () => {
  const { user } = useCore();
  const { id } = useParams();
  const [data, setData] = useState<PageDocument>({});
  const [control, setControl] = useState<CourseController | null>(null);

  const handleSave = async () => {
    if (control && id) {
      await control.course.update(id, "syllabus", cleanObject(data));
    }
    return true;
  };

  useEffect(() => {
    document.title = `Edit Course | ${process.env.REACT_APP_SITE_NAME}`;

    if (user.loading === false && user.data && id) {
      const control = new CourseController(user.data);
      setControl(control);
      return control.course.watchOne(id, (doc) => {
        // setState((s) => ({ ...s, docs, fetched: true }));
        setData(doc.syllabus || {});
      });
    }
  }, [user, id]);

  return (
    <PageEdit
      data={data}
      setData={setData}
      show={["heading", "image", "paragraph", "video"]}
      onSave={handleSave}
      back={`/course/${id}/syllabus`}
      maxWidth={"post" as Breakpoint} // xs, sm, post, md, lg, xl
      breadcrumbs={[
        { label: "Home", to: "/" },
        { label: "Course", to: `/course/${id}/lesson` },
        { label: `${data.title || "No Title"}` },
      ]}
    />
  );
};
