import logo from "./logo.svg";
import "./App.css";
import { CoreProvider } from "./components/core-sub";
import { phrainConfig, phrainApp } from "./controllers/firebase";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageCourse } from "./Pages/course";
import { PageCourseEdit } from "./Pages/course.edit";
import { PageLessonEdit } from "./Pages/course.lesson.edit";
import { initializeApp } from "./components/core-sub";
import { PageQuestionBankEdit } from "./Pages/course.question.edit";
import { PageCourseQuizEdit } from "./Pages/course.question.edit/index.add";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { PageCourseSyllabus } from "./Pages/course.syllabus";
import { PageView } from "./Pages/view";
import { getDatabase, get, ref } from "firebase/database";
import { Fragment, useEffect, useState } from "react";
import { ViewQuestion } from "Pages/view.question";
import { TestPage } from "Views/test";
import { Devtool } from "Pages/devtool";
import { PageSectionEdit } from "Pages/course.section.edit";

library.add(fad);
const app = initializeApp(phrainConfig);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {process.env.NODE_ENV === "development" && (
          <Fragment>
            <Route path="/devtool" element={<Devtool />} />
            <Route path="/test" element={<TestPage />} />
          </Fragment>
        )}
        <Route
          path="/view/:courseId/question/:questionId/rd/:redirect"
          element={<ViewQuestion />}
        />
        <Route
          path="/view/:courseId/question/:questionId/"
          element={<ViewQuestion />}
        />
        <Route
          path="/view/:courseId/lesson/:lessonId/rd/:redirect"
          element={<PageView />}
        />
        <Route path="/view/:courseId/lesson/:lessonId" element={<PageView />} />
        <Route path="/view/:courseId" element={<PageView />} />
        <Route
          path="/course/:id/question/:qid/quiz/create"
          element={<PageCourseQuizEdit />}
        />
        <Route
          path="/course/:id/question/:qid/quiz/:quizid"
          element={<PageCourseQuizEdit />}
        />
        <Route
          path="/course/:id/section/:sectionId/*"
          element={<PageSectionEdit />}
        />
        <Route
          path="/course/:id/section/:sectionId"
          element={<PageSectionEdit />}
        />
        <Route
          path="/course/:id/question/:qid"
          element={<PageQuestionBankEdit />}
        />
        <Route
          path="/course/:id/syllabus/edit"
          element={<PageCourseSyllabus />}
        />
        <Route path="/course/:id/lesson/:lid/*" element={<PageLessonEdit />} />
        <Route path="/course/:id/*" element={<PageCourseEdit />} />
        <Route path="/" element={<PageCourse />} />
      </Routes>
    </BrowserRouter>
  );
}

export default function AppCore() {
  const [appMenu, setAppMenu] = useState([]);

  useEffect(() => {
    if (phrainApp) {
      const rt = getDatabase(phrainApp);
      get(ref(rt, "/menus")).then((snapshot) => {
        setAppMenu(snapshot.val());
      });
    }
  }, []);

  return (
    <CoreProvider
      firebaseApp={app}
      theme={{
        typography: {
          body1: { fontFamily: "sarabun" },
          body2: { fontFamily: "sarabun" },
          caption: { fontFamily: "sarabun" },
          h1: { fontFamily: "Kanit" },
          h2: { fontFamily: "Kanit" },
          h3: { fontFamily: "Kanit" },
          h4: { fontFamily: "Kanit" },
          h5: { fontFamily: "Kanit" },
          h6: { fontFamily: "Kanit" },
        },
      }}
      logo={logo}
      appMenu={appMenu}
    >
      <App />
    </CoreProvider>
  );
}
