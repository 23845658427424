import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { BackLink, StockDisplay } from "../../components/core-sub";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { usePW } from ".";

const icons: { [key: string]: IconProp } = {
  lesson: ["fad", "blackboard"],
  question: ["fad", "list-ol"],
};

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "& .MuiListItemText-secondary": {
      color: "rgba(255,255,255,0.75)",
    },
  },
}));

export const PWSidebar = () => {
  const { courseId, lessonId, redirect, state } = usePW();
  const nav = useNavigate();

  const handleLink = (to: To, options?: NavigateOptions) => () =>
    nav(to, options);

  return (
    <div>
      <BackLink to={redirect || "/"} divider />
      {state.data?.feature && (
        <StockDisplay {...state.data.feature} ratio={1 / 3} />
      )}
      <ListItemButtonStyled
        selected={!lessonId}
        onClick={handleLink(`/view/${courseId}`)}
      >
        <ListItemText
          primary={state.data?.title}
          secondary={state?.data?.teacher}
          primaryTypographyProps={{ variant: "h6", color: "inherit" }}
          secondaryTypographyProps={{ variant: "caption" }}
        />
      </ListItemButtonStyled>
      <List dense>
        {state.data?.docs?.map((doc:any) => (
          <ListItemButtonStyled
            divider
            key={doc.id}
            selected={lessonId === doc.id}
            onClick={handleLink(
              redirect
                ? `/view/${courseId}/${doc.type}/${
                    doc.id
                  }/rd/${encodeURIComponent(redirect)}`
                : `/view/${courseId}/${doc.type}/${doc.id}`
            )}
          >
            <ListItemIcon
              sx={{
                pr: 2,
                color: lessonId === doc.id ? "white" : "inherit",
              }}
            >
              <FontAwesomeIcon
                size="2x"
                color="inherit"
                icon={icons[doc.type] || ["fad", "question"]}
              />
            </ListItemIcon>
            <ListItemText
              primary={doc.title}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItemButtonStyled>
        ))}
      </List>
    </div>
  );
};
