import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/material";
import { Container, MainContainer, PageDocument, PageViewer, useCore } from "components/core-sub";
import {
  CourseController,
  courseViewTypes,
  getSyllabus,
} from "controllers/course.controller";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PWSidebar } from "./sidebar";

interface stateTypes {
  loading: boolean;
  data?: courseViewTypes;
}

interface PageViewContextTypes {
  courseId?: string;
  lessonId?: string;
  redirect?: string;
  state: stateTypes;
  setState: React.Dispatch<React.SetStateAction<stateTypes>>;
}
const PageViewContext = React.createContext<PageViewContextTypes>({
  state: { loading: true },
  setState: () => {},
});
export const usePW = () => React.useContext(PageViewContext);

export const PageView = () => {
  const { user } = useCore();
  const { courseId, lessonId, redirect } = useParams();
  const [state, setState] = React.useState<stateTypes>({
    loading: true,
  });
  const nav = useNavigate();

  React.useEffect(() => {
    if (user.loading === false && user.data && courseId) {
      const control = new CourseController(user.data);
      control.course.getOne(courseId).then((data) => {
        setState((s) => ({ ...s, data, loading: false }));
      });
    }
  }, [user, courseId]);

  return (
    <PageViewContext.Provider
      value={{
        courseId,
        lessonId,
        redirect,
        state,
        setState,
      }}
    >
      <MainContainer
        dense
        loading={state.loading}
        signInOnly
        sidebar={<PWSidebar />}
        startActions={
          <IconButton
            onClick={() =>
              nav(
                lessonId
                  ? `/course/${courseId}/lesson/${lessonId}`
                  : `/course/${courseId}`
              )
            }
          >
            <FontAwesomeIcon icon={["fad", "edit"]} />
          </IconButton>
        }
      >
        <Box
          sx={{
            "& .public-DraftStyleDefault-block": {
              margin: 0,
            },
          }}
        >
          {!lessonId && state.data && (
            <React.Fragment>
              <Container maxWidth="post"></Container>
              <PageViewer
                maxWidth="post"
                data={getSyllabus(state.data)}
                noContainer
              />
            </React.Fragment>
          )}
          {state.data?.lessons?.find((lesson) => lesson.id === lessonId) && (
            <PageViewer
              data={
                state.data.lessons.find(
                  (lesson) => lesson.id === lessonId
                ) as PageDocument
              }
              noContainer
            />
          )}
        </Box>
      </MainContainer>
    </PageViewContext.Provider>
  );
};
