import { Box, TextField } from "@mui/material";
import { DialogPre, KuiButton } from "components/core-sub";

export const AddClass = () => {
  return (
    <DialogPre
      button={<KuiButton tx="add" />}
      title="Add Class"
      maxWidth={"sm"} // xs, sm, md, lg, xl
      // onConfirm={handleConfirm}
      // open={defaultOpen}
      // confirmButtonProps={<KuiButton tx="confirm" />}
      // secondaryActions={<KuiButton tx="add" />}
    >
      <TextField
        label="Title"
        type="text"
        inputProps={{ min: 0 }}
        variant="outlined"
        id="outlined-basic"
        fullWidth
      />
      <Box mt={2} />
      <TextField
        label="Date"
        type="text"
        inputProps={{ min: 0 }}
        variant="outlined"
        id="outlined-basic"
        fullWidth
      />
      <Box mt={2} />
      <TextField
        label="Start Time"
        type="text"
        inputProps={{ min: 0 }}
        variant="outlined"
        id="outlined-basic"
        fullWidth
      />
      <Box mt={2} />
      <TextField
        label="End Time"
        type="text"
        inputProps={{ min: 0 }}
        variant="outlined"
        id="outlined-basic"
        fullWidth
      />
      <Box mt={2} />
      <TextField
        label="Room"
        type="text"
        inputProps={{ min: 0 }}
        variant="outlined"
        id="outlined-basic"
        fullWidth
      />
    </DialogPre>
  );
};
