import { Box } from "@mui/material";
import {
  ActionIcon,
  Container,
  ContentHeader,
  DataGrid,
  DataGridRowsProp,
  KuiActionIcon,
  useCore,
} from "components/core-sub";
import { AddClass } from "./add.class";

export const PageSectionClass = () => {
  const { t } = useCore();
  const rows: DataGridRowsProp = [
    { id: 1, col1: "Test", col2: "Test Data #1" },
  ];

  return (
    <Box py={6}>
      <Container maxWidth="post">
        <ContentHeader
          label={t("Class")}
          breadcrumbs={[
            { label: t("Section"), to: "/" },
            { label: t("Courses.MyCourse"), to: `/` },
          ]}
          actions={
            <AddClass />
          }
        />
        <DataGrid
          rows={rows}
          columns={[
            {
              field: "action",
              headerName: "Action",
              width: 64,
              align: "center",
              renderCell: ({ row }) => {
                if (row.visibility === "trash") {
                  return (
                    <>
                      <ActionIcon icon={["fad", "redo"]} />
                      <KuiActionIcon tx="remove" />
                    </>
                  );
                }
                return (
                  <>
                    <KuiActionIcon tx="edit" />
                    <KuiActionIcon tx="remove" />
                  </>
                );
              },
            },
          ]}
        />
      </Container>
    </Box>
  );
};
