import { db } from "controllers/firebase";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  query,
  QueryDocumentSnapshot,
  where,
} from "firebase/firestore";

export class cloneQuestionController {
  prefix: string = process.env.REACT_APP_PREFIX || "";

  collection = (path: string, ...pathsection: string[]) =>
    collection(db, "clients", this.prefix, path, ...pathsection);
  doc = (path: string, ...pathsection: string[]) =>
    doc(db, "clients", this.prefix, path, ...pathsection);
  private readonly toDoc = (doc: QueryDocumentSnapshot<DocumentData>) => ({
    ...doc.data(),
    id: doc.id,
  });
  quizList = async (questionBankId: string) => {
    const lists = (
      await getDocs(
        query(this.collection("questions"), where("questionparent", "==", questionBankId))
      )
    ).docs.map(this.toDoc);
    return lists;
  };
}
