import {
  BackLink,
  FeatureImageEdit,
  useCore,
  StockDisplayProps,
  useAlerts,
  cleanObject,
  TitleEdit,
  KuiActionIcon,
  DialogRemove,
  KuiListItemButton,
} from "../../components/core-sub";
import { List, ListItemText, ListItemSecondaryAction } from "@mui/material";
import { SelectVisibility } from "../../components/select.visibilisy";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCourseEdit } from ".";
// import { DownloadBackup } from "./download.backup";
import { deleteField } from "firebase/firestore";

export const Sidebar = () => {
  const { t } = useCore();
  const { addAlert } = useAlerts();
  const { id, tab, data, control } = useCourseEdit();
  const nav = useNavigate();
  const [remove, setRemove] = useState<boolean>(false);

  const handleChange = (key: string) => async (value: string) => {
    if (id && control) {
      const filterValue = cleanObject(value);
      await control.course.update(id, key, filterValue);
      addAlert({ label: "Saved" });
    }
  };
  const handleChangeCover = async (data: StockDisplayProps) => {
    if (id && control) {
      const filterValue = cleanObject(data);
      await control.course.update(id, "feature", filterValue);
      addAlert({ label: "Saved" });
    }
  };
  const handleRemoveSyllabus = async () => {
    if (id && control) {
      await control.course.update(id, "syllabus", null);
    }
    setRemove(false);
  };
  const handleRemoveFeature = async () => {
    if (id && control) {
      await control.course.update(id, "feature", deleteField());
      addAlert({ label: "Feature Removed" });
    }
  };
  const handleChangeVisibility = async (value:string) => {
    if (id && control) {
      await control.course.update(id, 'type', value);
      addAlert({ label: "Saved" });
    }
  }

  return (
    <>
      <BackLink to={"/"} divider />
      <TitleEdit value={data?.title} onChange={handleChange("title")} />
      <FeatureImageEdit
        value={data?.feature}
        onChange={handleChangeCover}
        onRemove={handleRemoveFeature}
      />
      <SelectVisibility
        id={id}
        value={data?.type}
        onChange={handleChangeVisibility}
        prefix="course"
      />
      <List disablePadding>
        {[
          { label: t("Description"), key: "desc" },
          {
            label: t("Syllabus"),
            key: "syllabus",
            actions: (
              <Fragment>
                <KuiActionIcon
                  tx="edit"
                  onClick={() => nav(`/course/${id}/syllabus/edit`)}
                />
                {data?.syllabus ? (
                  <KuiActionIcon tx="remove" onClick={() => setRemove(true)} />
                ) : undefined}
              </Fragment>
            ),
          },
          { label: t("Lesson"), key: "lesson" },
          { label: t("Question Bank"), key: "question" },
          // { label: t("Section"), key: "section" },
        ].map((item, index) => (
          <KuiListItemButton
            divider
            dense
            selected={tab === item.key || (index === 0 && !tab)}
            key={item.key}
          >
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{ variant: "body2" }}
              onClick={() => nav(`/course/${id}/${item.key}`)}
            />
            {item.actions && (
              <ListItemSecondaryAction>{item.actions}</ListItemSecondaryAction>
            )}
          </KuiListItemButton>
        ))}
        {/* <DownloadBackup /> */}
      </List>
      <DialogRemove
        title="Remove"
        label="Do you want to remove syllabus?"
        open={Boolean(remove)}
        onClose={() => setRemove(false)}
        onConfirm={handleRemoveSyllabus}
      />
    </>
  );
};
