import {
  useCore,
  QuizEditor,
  QuizDocument,
  Container,
  MainContainer,
  BackLink,
  SaveButton,
  useAlerts,
  TitleDebounce,
} from "components/core-sub";
import { CourseController } from "controllers/course.controller";
import { useEffect, useState } from "react";
import update from "react-addons-update";
import { useParams } from "react-router-dom";

export const PageCourseQuizEdit = () => {
  const { user, t } = useCore();
  const { id, qid, quizid } = useParams();
  const { addAlert } = useAlerts();
  const [data, setData] = useState<QuizDocument>({});

  const [control, setControl] = useState<CourseController | null>(null);

  const handleSave = async () => {
    if (control && qid && id && quizid) {
      await CourseController.question.update(quizid, data);
      addAlert({ label: t("Saved") });
    }
  };

  useEffect(() => {
    document.title = `Lesson | ${process.env.REACT_APP_SITE_NAME}`;
    if (user.loading === false && user.data) {
      const control = new CourseController(user.data);
      setControl(control);
      if (quizid) {
        return CourseController.question.watchOne(quizid, setData);
      }
    }
  }, [user, quizid]);

  return (
    <MainContainer
      signInOnly
      sidebar={
        <>
          <BackLink divider to={`/course/${id}/question/${qid}`} />
          <SaveButton onSave={handleSave} />
          <TitleDebounce
            value={data?.title}
            onChange={(value) =>
              setData((d) => update(d, { title: { $set: value } }))
            }
          />
        </>
      }
    >
      <Container maxWidth="md">
        <QuizEditor data={data} setData={setData} />
      </Container>
    </MainContainer>
  );
};
