import { Box } from "@mui/material";
import { ActionIcon, Container, ContentHeader, DataGrid, DataGridRowsProp, KuiActionIcon, KuiButton, useCore } from "components/core-sub";
import { GradeSetting } from "./grading.setting";


export const PageSectionGrading = () => {
  const { t } = useCore();
  const rows: DataGridRowsProp = [
    { id: 1, col1: "Test", col2: "Test Data #1" },
  ];

    return (
      <Box py={6}>
      <Container maxWidth="lg">
        <ContentHeader
          label={t("Grading")}
          breadcrumbs={[
            { label: t("Section"), to: "/" },
            { label: t("Courses.MyCourse"), to: `/` },
          ]}
          actions={
            <>
              <GradeSetting />
              &nbsp;
              <KuiButton tx="browse" />
            </>
          }
        />
        <DataGrid
          rows={rows}
          columns={[
            {
              field: "action",
              headerName: "Action",
              width: 64,
              align: "center",
              renderCell: ({ row }) => {
                if (row.visibility === "trash") {
                  return (
                    <>
                      <ActionIcon icon={["fad", "redo"]} />
                      <KuiActionIcon tx="remove" />
                    </>
                  );
                }
                return (
                  <>
                    <KuiActionIcon tx="edit" />
                    <KuiActionIcon tx="remove" />
                  </>
                );
              },
            },
          ]}
        />
      </Container>
    </Box>
    );
  };
  