import {
  ActionIcon,
  DataGrid,
  DataGridRowsProp,
  DialogPre,
  KuiActionIcon,
  KuiButton,
} from "components/core-sub";

export const AddStudents = () => {
  const rows: DataGridRowsProp = [
    { id: 1, col1: "Test", col2: "Test Data #1" },
  ];
  return (
    <DialogPre
      button={<KuiButton tx="add" />}
      title="Student"
      maxWidth={"sm"} // xs, sm, md, lg, xl
      // onConfirm={handleConfirm}
      // open={defaultOpen}
      // confirmButtonProps={ButtonProps}
      secondaryActions={<KuiButton tx="add" />}
    >
      <DataGrid
        rows={rows}
        columns={[
          {
            field: "action",
            headerName: "Action",
            width: 64,
            align: "center",
            renderCell: ({ row }) => {
              if (row.visibility === "trash") {
                return (
                  <>
                    <ActionIcon icon={["fad", "redo"]} />
                    <KuiActionIcon tx="remove" />
                  </>
                );
              }
              return (
                <>
                  <KuiActionIcon tx="edit" />
                  <KuiActionIcon tx="remove" />
                </>
              );
            },
          },
        ]}
      />
    </DialogPre>
  );
};
