import { KuiButton, useCore } from "components/core-sub";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  QuestionBank,
  QuestionBankDocument,
} from "controllers/course.controller";
import * as React from "react";
import { useParams } from "react-router-dom";

export const LessonQuestionContext = React.createContext<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  open: false,
  setOpen: () => {},
});

export const MenuItemQuestion = ({
  onClick,
  ...props
}: ListItemButtonProps) => {
  const { t } = useCore();
  const { setOpen } = React.useContext(LessonQuestionContext);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(event);
    setOpen(true);
  };

  return (
    <ListItemButton dense {...props} onClick={handleClick}>
      <ListItemText primary={t("Question")} />
    </ListItemButton>
  );
};

export interface QuestionData {
  id?: string;
  title: string;
  questionid: string;
  amount: string;
  point: number;
  fixnumber: number;
  type: string;
  visibility: "public" | "private";
}
const defaultData: QuestionData = {
  title: "",
  questionid: "",
  amount: "0",
  point: 0,
  fixnumber: 1,
  type: `question`,
  visibility: "private",
};

export interface LessonQuestionEditorProps {
  children?: React.ReactNode;
  clearAfterSave?: boolean;
  data?: QuestionData;
}
export const LessonQuestionEditor = (props: LessonQuestionEditorProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { t, user } = useCore();
  const { id: courseId } = useParams();
  const [qb, setQb] = React.useState<{
    loading: boolean;
    docs: QuestionBankDocument[];
  }>({
    loading: true,
    docs: [],
  });
  const [data, setData] = React.useState<QuestionData>({ ...defaultData });

  const isComplete = () =>
    (Object.keys(defaultData) as (keyof QuestionData)[]).every((key) =>
      Boolean(data[key])
    );
  const getBankValue = (): string => {
    return qb.docs.find((doc) => doc.id === data.questionid)
      ? data.questionid
      : "";
  };
  const handleChangeBank = ({ target: { value } }: SelectChangeEvent) => {
    const bank = qb.docs.find((doc) => doc.id === value);
    if (bank) {
      setData((s) => ({
        ...s,
        title: bank.title,
        questionid: bank.id,
      }));
    }
  };
  const handleChange =
    (field: keyof QuestionData) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setData((d) => ({ ...d, [field]: value }));
  const handleChangeSelect =
    (field: keyof QuestionData) =>
    ({ target: { value } }: SelectChangeEvent<string>) =>
      setData((d) => ({ ...d, [field]: value }));
  const handleConfirm = async () => {
    if (isComplete() && user.data && courseId) {
      const control = new QuestionBank();
      if (data.id) {
        await control.updateToLesson(data);
      } else {
        await control.addToLesson(user.data, courseId, data);
      }
      setOpen(false);
      if (props.clearAfterSave) {
        setData({ ...defaultData });
      }
    }
  };

  const genProps = (field: keyof QuestionData): TextFieldProps => ({
    variant: "outlined",
    fullWidth: true,
    value: data[field],
    onChange: handleChange(field),
    sx: { mb: 2 },
  });

  React.useEffect(() => {
    if (courseId) {
      const control = new QuestionBank();
      return control.watchList(courseId, (docs) =>
        setQb((s) => ({ ...s, loading: false, docs }))
      );
    }
  }, [courseId]);

  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <LessonQuestionContext.Provider value={{ open, setOpen }}>
      {props.children}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Question</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2, mt: 1 }}>
            <InputLabel id="add-quiz-label">Question Bank</InputLabel>
            <Select
              labelId="add-quiz-label"
              label="Question Bank"
              value={getBankValue()}
              onChange={handleChangeBank}
            >
              <MenuItem value="" disabled>
                -- เลือก Question Bank --
              </MenuItem>
              {qb.docs.map((val) => (
                <MenuItem value={val.id} key={val.id}>
                  {val.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField label={t("Title")} {...genProps("title")} />
          <TextField
            label="จำนวนข้อ"
            type="number"
            inputProps={{ min: 0 }}
            {...genProps("amount")}
          />
          <TextField
            label="คะแนน"
            type="number"
            inputProps={{ min: 0 }}
            {...genProps("point")}
          />
          <TextField
            label="จำนวนครั้ง"
            type="number"
            inputProps={{ min: 0 }}
            {...genProps("fixnumber")}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="add-quiz-label">{t("Visibility")}</InputLabel>
            <Select
              labelId="add-quiz-label"
              label={t("Visibility")}
              value={data.visibility}
              onChange={handleChangeSelect("visibility")}
            >
              <MenuItem value="private">Private</MenuItem>
              <MenuItem value="public">Public</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <KuiButton
            tx="confirm"
            disabled={!isComplete()}
            onClick={handleConfirm}
          />
          <KuiButton tx="close" onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </LessonQuestionContext.Provider>
  );
};
