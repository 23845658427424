import { phrainApp } from "./firebase";
import { User } from "firebase/auth";
import { arrayShuffle, cleanObject, PageDocument } from "components/core-sub";
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  Unsubscribe,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { QuizDocument, StockDisplayProps } from "components/core-sub";

export interface courseTypes {
  datecreate: Timestamp;
  datemodified: Timestamp;
  id: string;
  title: string;
  type: "course" | "course-private" | "course-remove";
  user: string;
  cover?: StockDisplayProps;
  feature?: StockDisplayProps;
  teacher?: string;
  desc?: string;
  category?: string;
  syllabus?: PageDocument;
  visibility?: "public" | "private" | "trash";
}

export interface courseViewTypes extends courseTypes {
  lessons?: lessonDocument[];
  docs?: lessonDocument[];
}

export interface AutomateTypes {
  id?: string;
  parent: string;
  path: { collection: string; doc?: string }[];
  time: string;
  epoch: number;
  type: string;
  user: string;
  value: any;
}

export interface lessonDocument {
  contents: any[];
  datecreate?: Timestamp;
  datemodified?: Timestamp;
  id: string;
  parent: string;
  prefix?: string;
  sort: number;
  title: string;
  type: string;
  user: string;
  visibility?: "public" | "private";
}

export interface questionbankForLessons {
  title: string;
  questionid: string;
  amount: string;
  point: number;
  fixnumber: number;
  type: string;
}

export interface QuestionBankDocument {
  id: string;
  title: string;
  datecreate?: Timestamp;
  datemodified?: Timestamp;
  user: string;
  type: string;
  parent: string;
}

export interface ViewQuestionType extends questionbankForLessons {
  docs: (QuizDocument & { id: string })[];
}

export class CourseController {
  private user: User;
  private static prefix: string = `${process.env.REACT_APP_PREFIX}`;
  private static db: Firestore = getFirestore(phrainApp);

  constructor(user: User) {
    this.user = user;
  }

  private static collection(
    path: string,
    ...pathSegments: string[]
  ): CollectionReference<DocumentData> {
    return collection(
      CourseController.db,
      "clients",
      CourseController.prefix,
      path,
      ...pathSegments
    );
  }
  private static doc(path: string, ...pathSegments: string[]) {
    return doc(
      CourseController.db,
      "clients",
      CourseController.prefix,
      path,
      ...pathSegments
    );
  }
  private static docParse<T extends unknown>(
    doc: QueryDocumentSnapshot<DocumentData>
  ): T {
    return Object.assign({}, doc.data(), { id: doc.id }) as T;
  }

  watch = (callback: (docs: courseTypes[]) => void) => {
    const q = query(
      CourseController.collection("courses"),
      where("user", "==", this.user.uid),
      where("type", "in", [`course`, `course-private`, `course-remove`]),
      orderBy("datemodified", "desc")
    );
    return onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as courseTypes[];
      callback(docs);
    });
  };
  add = async (title: string) => {
    return addDoc(CourseController.collection("courses"), {
      title,
      datecreate: serverTimestamp(),
      datemodified: serverTimestamp(),
      type: `course`,
      visibility: "private",
      user: this.user.uid,
    } as courseTypes).catch((err) => {
      throw new Error(err.message);
    });
  };
  remove = async (id: string) => {
    return await updateDoc(CourseController.doc("courses", id), {
      type: `course-remove`,
      user: this.user.uid,
    });
  };

  private toDoc = (doc: QueryDocumentSnapshot<DocumentData>) => ({
    ...doc.data(),
    id: doc.id,
  });
  private courseConvert = (doc: any): courseTypes => {
    if (doc.cover && !doc?.cover?.image) {
      doc.cover = Object.assign({}, { image: doc.cover });
    }
    return doc;
  };
  course = {
    watch: (callback: (docs: courseTypes[]) => void) => {
      const q = query(
        CourseController.collection("courses"),
        where("user", "==", this.user.uid),
        where("type", "in", [`course`, `course-private`]),
        orderBy("datemodified", "desc")
      );
      return onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as courseTypes[];
        callback(docs);
      });
    },
    getOne: async (id: string): Promise<courseViewTypes> => {
      const snapshot = await getDoc(CourseController.doc("courses", id));
      const course = this.courseConvert({ ...snapshot.data() });

      const lessons = (
        await this.lesson.getFromParents(id, ["private", "public"])
      ).sort((a, b) => a.sort - b.sort) as lessonDocument[];

      const docs = await this.course.getChild(id);

      return Object.assign({}, course, { lessons, docs });
    },
    watchOne: (id: string, callback: (data: courseTypes) => void) => {
      return onSnapshot(CourseController.doc("courses", id), (snap) => {
        callback(this.courseConvert({ ...snap.data() }));
      });
    },
    watchBin: (callback: (docs: courseTypes[]) => void) => {
      return onSnapshot(
        query(
          CourseController.collection("courses"),
          where("user", "==", this.user.uid),
          where("type", "==", "course-remove")
        ),
        (snapshot) => {
          callback(snapshot.docs.map(this.toDoc) as courseTypes[]);
        }
      );
    },
    update: async (id: string, field: string, value: any) => {
      return await setDoc(
        CourseController.doc("courses", id),
        {
          [field]: value,
          datemodified: serverTimestamp(),
        },
        { merge: true }
      );
    },
    updateModified: async (id: string) =>
      await updateDoc(CourseController.doc("courses", id), {
        datemodified: serverTimestamp(),
      }),
    restoreBin: async (id: string) =>
      await updateDoc(CourseController.doc("courses", id), {
        type: `course-private`,
      }),
    removeBin: async (id: string): Promise<void> => {
      const setBatch = () => writeBatch(CourseController.db);
      const refById = (id: string) => CourseController.doc("courses", id);
      const refQuestionById = (id: string) =>
        CourseController.doc("questions", id);

      const items = (
        await getDocs(
          query(
            CourseController.collection("courses"),
            where("parent", "==", id)
          )
        )
      ).docs.map(this.toDoc);

      for (let i = 0; i < items.length; i++) {
        const batch = setBatch();
        const item = items[i];
        (
          await getDocs(
            query(
              CourseController.collection("questions"),
              where("parent", "==", item.id)
            )
          )
        ).docs.map((doc) => batch.delete(refQuestionById(doc.id)));
        await batch.commit();
      }

      const mainBatch = setBatch();

      items.forEach((item) => mainBatch.delete(refById(item.id)));
      mainBatch.delete(refById(id));

      await mainBatch.commit();
    },
    getChild: async (parent: string) => {
      const snapshot = await getDocs(
        query(
          CourseController.collection("courses"),
          where("parent", "==", parent)
        )
      );
      const childrens = snapshot.docs
        .map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
            } as lessonDocument)
        )
        .sort((a, b) => a.sort - b.sort);
      return childrens;
    },
  };

  lesson = {
    watchMany: (
      courseId: string,
      callback: (docs: lessonDocument[]) => void
    ) => {
      return onSnapshot(
        query(
          CourseController.collection("courses"),
          where("user", "==", this.user.uid),
          where("parent", "==", courseId)
        ),
        (snapshot) => {
          const docs = snapshot.docs
            .map((doc) => this.toDoc(doc) as lessonDocument)
            .filter((doc) => !doc.type.includes("remove"))
            .sort((a, b) => a.sort - b.sort);
          callback(docs);
        },
        (error) => {
          throw new Error(error.message);
        }
      );
    },
    watchById: (id: string, callback: (data: PageDocument) => void) => {
      return onSnapshot(CourseController.doc("courses", id), (snap) => {
        callback({ ...snap.data() } as PageDocument);
      });
    },
    getFromParents: async (
      parent: string,
      visibility: unknown = "public"
    ): Promise<lessonDocument[]> => {
      const lessonsSnapshot = await getDocs(
        query(
          CourseController.collection("courses"),
          where("parent", "==", parent),
          where(
            "visibility",
            Array.isArray(visibility) ? "in" : "==",
            visibility
          )
        )
      );
      const lessons = lessonsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as lessonDocument[];
      return lessons;
    },
    updateModified: async (lessonId: string, data: PageDocument) => {
      const newData = JSON.parse(JSON.stringify(data));

      await updateDoc(CourseController.doc("courses", lessonId), {
        ...newData,
        type: "lesson",
        datemodified: serverTimestamp(),
      });
    },

    add: async (
      courseId: string,
      title: string
    ): Promise<DocumentReference<DocumentData>> => {
      const doc = await addDoc(CourseController.collection("courses"), {
        title,
        datecreate: serverTimestamp(),
        datemodified: serverTimestamp(),
        type: "lesson",
        user: this.user.uid,
        parent: courseId,
        prefix: process.env.REACT_APP_PREFIX,
        sort: 0,
        visibility: "private",
      } as lessonDocument).catch((err) => {
        throw new Error(err.message);
      });

      return doc;
    },
    remove: async (lessonId: string) => {
      return await deleteDoc(CourseController.doc("courses", lessonId));
    },
    sorting: async (courseId: string, docs: lessonDocument[]) => {
      await this.course.updateModified(courseId);
      const batch = writeBatch(CourseController.db);
      docs.forEach((doc, index) => {
        batch.update(CourseController.doc("courses", doc.id), {
          sort: index,
        });
      });
      await batch.commit();
    },
  };

  questionbank = {
    getOne: async (qid: string): Promise<QuestionBankDocument | undefined> => {
      const snap = await getDoc(CourseController.doc("questions", qid));
      const doc = snap.data() as QuestionBankDocument;
      return snap.exists() ? doc : undefined;
    },

    update: async (qid: string, field: string, value: any) => {
      return await updateDoc(CourseController.doc("questions", qid), {
        [field]: value,
        datemodified: serverTimestamp(),
      });
    },

    watchMany: (
      courseId: string,
      callback: (docs: QuestionBankDocument[]) => void
    ) => {
      return onSnapshot(
        query(
          CourseController.collection("questions"),
          where("user", "==", this.user.uid),
          where("parent", "==", courseId),
          where("type", "==", "category")
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(this.toDoc) as lessonDocument[];
          callback(docs);
        },
        (error) => {
          throw new Error(error.message);
        }
      );
    },

    updateModified: async (val: string, qid: string) => {
      await updateDoc(CourseController.doc("questions", qid), {
        // ...newData,
        title: val,
        datemodified: serverTimestamp(),
      });
    },

    add: async (
      courseId: string,
      title: string
    ): Promise<DocumentReference<DocumentData>> => {
      const doc = await addDoc(CourseController.collection("questions"), {
        title,
        datecreate: serverTimestamp(),
        datemodified: serverTimestamp(),
        user: this.user.uid,
        type: "category",
        parent: courseId,
      } as lessonDocument).catch((err) => {
        throw new Error(err.message);
      });

      return doc;
    },

    addQuestionforLesson: async (
      data: questionbankForLessons,
      _visit: string,
      courseID: string
    ) => {
      console.log(data);
      const doc = await addDoc(CourseController.collection("courses"), {
        ...data,
        user: this.user.uid,
        type: "question",
        sort: 0,
        prefix: "cu-sti-",
        parent: courseID,
        datecreate: serverTimestamp(),
        datemodified: serverTimestamp(),
      }).catch((err) => {
        throw new Error(err.message);
      });
      return doc;
    },

    remove: async (questionBankId: string) => {
      const batch = writeBatch(CourseController.db);

      const quiz = (
        await getDocs(
          query(
            CourseController.collection("questions"),
            where("parent", "==", questionBankId)
          )
        )
      ).docs;
      quiz.forEach((doc) => {
        batch.delete(CourseController.doc("questions", doc.id));
      });

      batch.delete(CourseController.doc("questions", questionBankId));
      await batch.commit();
    },
  };

  static readonly question = {
    add: async (
      user: User,
      quizId: string,
      courseId: string,
      title: string
    ): Promise<DocumentReference<DocumentData>> => {
      const doc = await addDoc(this.collection("questions"), {
        title,
        user: user.uid,
        questionparent: quizId,
        courseparent: courseId,
        datecreate: serverTimestamp(),
        datemodified: serverTimestamp(),
      }).catch((err) => {
        throw new Error(err.message);
      });
      return doc;
    },
    update: async (quizid: string, data: QuizDocument) => {
      return await setDoc(
        this.doc("questions", quizid),
        { ...cleanObject(data), datemodified: serverTimestamp() },
        {
          merge: true,
        }
      );
    },
    watchOne: (questionId: string, callback: (data: QuizDocument) => void) => {
      return onSnapshot(
        CourseController.doc("questions", questionId),
        (snap) => {
          callback({ ...snap.data() } as QuizDocument);
        }
      );
    },
    watch: (
      user: User,
      questionId: string,
      callback: (docs: QuizDocument[]) => void
    ) => {
      return onSnapshot(
        query(
          CourseController.collection("questions"),
          where("user", "==", user.uid),
          where("questionparent", "==", questionId)
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(doc => this.docParse<QuizDocument>(doc))
          callback(docs);
        },
        (error) => {
          throw new Error(error.message);
        }
      );
    },
    remove: (id: string) => deleteDoc(this.doc("questions", id)),
  };

  automate = {
    watch: (id: string, callback: (doc: AutomateTypes | null) => void) => {
      return onSnapshot(
        query(
          collection(CourseController.db, "automates"),
          where("parent", "==", id)
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(this.toDoc) as AutomateTypes[];
          if (docs.length) {
            callback(docs[0]);
          } else {
            callback(null);
          }
        }
      );
    },
    add: async (data: AutomateTypes): Promise<void> => {
      await addDoc(collection(CourseController.db, "automates"), data);
    },
    remove: async (id: string): Promise<void> => {
      await deleteDoc(doc(CourseController.db, "automates", id));
    },
  };

  view = {
    question: async (
      questionId: string
    ): Promise<
      questionbankForLessons & { docs: (QuizDocument & { id: string })[] }
    > => {
      const snap = await getDoc(CourseController.doc("courses", questionId));
      const question = snap.data() as questionbankForLessons;
      const docs = arrayShuffle(
        (
          await getDocs(
            query(
              CourseController.collection("questions"),
              where("questionparent", "==", question.questionid)
            )
          )
        ).docs.map((doc): QuizDocument & { id: string } => ({
          ...doc.data(),
          id: doc.id,
        }))
      ).slice(0, parseInt(question.amount));
      return { ...question, docs };
    },
  };
}

export const getSyllabus = (data: courseTypes): PageDocument => {
  const genKey = (): string => Math.round(Math.random() * 1000000).toString();

  if (data.syllabus) {
    return {
      ...data.syllabus,
      title: data.title,
    };
  } else {
    return {
      title: data.title,
      contents: [
        {
          type: "heading",
          heading: { variant: "h6", value: "Teacher" },
          key: genKey(),
        },
        {
          type: "paragraph",
          paragraph: { value: data.teacher },
          mb: 3,
          key: genKey(),
        },
        {
          type: "heading",
          heading: { variant: "h6", value: "Description" },
          key: genKey(),
        },
        {
          type: "paragraph",
          paragraph: { value: data.desc },
          mb: 3,
          key: genKey(),
        },
        {
          type: "heading",
          heading: { variant: "h6", value: "Subject" },
          key: genKey(),
        },
        {
          type: "paragraph",
          paragraph: { value: data.category },
          mb: 3,
          key: genKey(),
        },
      ],
    };
  }
};

export class MainClass {
  protected prefix: string = `${process.env.REACT_APP_PREFIX}`;
  protected db: Firestore = getFirestore(phrainApp);

  protected collection(
    path: string,
    ...pathSegments: string[]
  ): CollectionReference<DocumentData> {
    return collection(this.db, "clients", this.prefix, path, ...pathSegments);
  }
  protected doc(path: string, ...pathSegments: string[]) {
    return doc(this.db, "clients", this.prefix, path, ...pathSegments);
  }
  protected toDoc = (doc: QueryDocumentSnapshot<DocumentData>) => ({
    ...doc.data(),
    id: doc.id,
  });
}

export interface QuestionData {
  id?: string;
  title: string;
  questionid: string;
  amount: string;
  point: number;
  fixnumber: number;
  type: string;
  visibility: "public" | "private";
}

export class QuestionBank extends MainClass {
  watchList(
    courseId: string,
    callback: (docs: QuestionBankDocument[]) => void
  ): Unsubscribe {
    return onSnapshot(
      query(
        this.collection("questions"),
        where("type", "==", "category"),
        where("parent", "==", courseId)
      ),
      (snapshot) => {
        const docs = snapshot.docs.map(
          (doc) => this.toDoc(doc) as QuestionBankDocument
        );
        callback(docs);
      }
    );
  }
  addToLesson = async (
    user: User,
    courseId: string,
    data: questionbankForLessons
  ) => {
    const doc = await addDoc(this.collection("courses"), {
      ...cleanObject(data),
      user: user.uid,
      type: "question",
      sort: 0,
      parent: courseId,
      datecreate: serverTimestamp(),
      datemodified: serverTimestamp(),
    }).catch((err) => {
      throw new Error(err.message);
    });
    return doc;
  };

  updateToLesson = async (data: QuestionData) => {
    if (data.id) {
      const { id, ...newData } = data;
      await updateDoc(this.doc("courses", id), {
        ...newData,
      });
    }
  };
}
