import { MainContainer } from "components/core-sub";
import { PageSectionChat } from "Pages/course.section.chat";
import { PageSectionClass } from "Pages/course.section.class";
import { PageSectionGrading } from "Pages/course.section.grading";
import { PageSectionStudent } from "Pages/course.section.student";
import { Route, Routes, useParams } from "react-router-dom";
import { Sidebar } from "./sidebar";

export const PageSectionEdit = () => {
  const { id } = useParams();
  return (
    <MainContainer dense signInOnly sidebar={<Sidebar id={id} />}>
      <Routes>
        <Route path="/chat" element={<PageSectionChat />} />
        <Route path="/grading" element={<PageSectionGrading />} />
        <Route path="/class" element={<PageSectionClass />} />
        <Route path="/*" element={<PageSectionStudent />} />
      </Routes>
    </MainContainer>
  );
};
